import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { AclTenant } from '@client/shared/api';
import { useUi } from '@client/shared/store';
import { ProtectedNavLink, ROUTES_CONFIG } from '@client/shared/permissions';
import { useTranslation } from 'react-i18next';
import { createTenantLink } from '@client/shared/utilities';
import { UserAvatar } from '@client/shared/toolkit';

export const MainNavigationUserMenu = () => {
  const { t } = useTranslation();

  const ui = useUi();
  const user = ui.appUser;
  const tenants = user.tenants ?? [];
  const tenant = user.tenant;

  const switchTenant = useCallback((tenant: AclTenant) => {
    window.location.href = createTenantLink(tenant.subdomain);
  }, []);

  return (
    <Menu as="div" className="relative inline-block text-left z-20">
      <MenuButton>
        <div className="flex space-x-3 items-center">
          <UserAvatar
            userId={user?.userId ?? ''}
            userName={user?.fullName ?? ''}
            lastUpdate={user?.lastUpdate ?? ''}
            hasAvatar={user?.hasAvatar ?? false}
            classNames="w-10 h-10"
          />
          <div className="text-left">
            <div className="font-bold text-base">{user?.fullName}</div>
            <div className="-mt-1 text-sm font-light">{tenant?.name}</div>
          </div>
        </div>
      </MenuButton>
      <MenuItems className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
        <>
          <div className="p-3">
            <div className="flex space-x-3 items-center text-black">
              <div className="flex-shrink-0">
                <UserAvatar
                  userId={user?.userId ?? ''}
                  userName={user?.fullName ?? ''}
                  lastUpdate={user?.lastUpdate ?? ''}
                  hasAvatar={user?.hasAvatar ?? false}
                  classNames="w-12 h-12 "
                />
              </div>
              <div className="text-left truncate">
                <div className="font-bold text-lg truncate">{user?.fullName}</div>
                <div className="-mt-1 text-sm font-light truncate">{user?.email}</div>
              </div>
            </div>
          </div>
          <div className="bg-gray-200 h-[1px]" />
          <ProtectedNavLink
            routeConfig={ROUTES_CONFIG.SETTINGS}
            className="text-black w-full px-4 py-2 h-14 text-base flex items-center border-b hover:bg-blue-200"
            activeClassName="bg-blue-200"
            disabledClassName="text-black w-full px-4 py-2 h-14 text-base flex items-center border-b"
          >
            {t('app.menuSettings')}
          </ProtectedNavLink>
          <ProtectedNavLink
            routeConfig={ROUTES_CONFIG.HELP}
            className="text-black w-full px-4 py-2 h-14 text-base flex items-center border-b hover:bg-blue-200"
            activeClassName="bg-blue-200"
            disabledClassName="text-black w-full px-4 py-2 h-14 text-base flex items-center border-b"
          >
            {t('app.menuHelp')}
          </ProtectedNavLink>
          <div className="bg-gray-200 h-[1px]" />
          <div className="p-3 text-gray-600 bg-gray-200 text-xs font-bold">
            {t('app.menuSectionCompany', { count: tenants.length })}
          </div>
          <div className="max-h-[300px] overflow-y-auto">
            {tenants.map((tenant) => (
              <MenuItem key={tenant.tenantId}>
                {({ focus }) => {
                  const activeTenant = user?.tenant?.tenantId === tenant.tenantId;
                  return (
                    <button
                      onClick={() => switchTenant(tenant)}
                      className={classNames(
                        'text-black w-full px-4 py-2 h-14 text-base flex items-center border-b justify-between',
                        { 'font-bold': activeTenant, 'bg-blue-200': focus }
                      )}
                    >
                      {tenant.name}
                      <div className="h-4 w-4 border-2 rounded-full border-neutral-500 focus:ring-2 focus:ring-blue-300 flex items-center justify-center">
                        <div className={classNames('w-2 h-2 rounded-full', { 'bg-black': activeTenant })}></div>
                      </div>
                    </button>
                  );
                }}
              </MenuItem>
            ))}
          </div>
          <div className="bg-gray-200 h-2" />
          <MenuItem>
            {({ focus }) => (
              <NavLink
                to="/logout"
                className={classNames('text-black w-full px-4 py-2 h-14 text-base flex items-center', {
                  'bg-blue-200': focus,
                })}
              >
                {t('common.logout')}
              </NavLink>
            )}
          </MenuItem>
        </>
      </MenuItems>
    </Menu>
  );
};
