import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { Assignment, CatalogElementRestBudget, useApiGetCalculationModelRestBudgetMutation, useApiProjectGetUserDefinedFieldsDefinitionQuery } from '@client/shared/api';
import {
  BaseSelect,
  EditNodeIcon,
  LoadingIndicator,
  LoyaltyIcon,
  MultiSelect,
  NumberInput,
  PercentageIcon,
  StocksIcon
} from '@client/shared/toolkit';
import { CostElementMultiSelect, findCostElementRestBudget, useFlattenCostElementRestBudgets } from '@client/project/shared';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { safeMutation } from '@client/shared/utilities';

interface VatReturnInputProps {
  className?: string;
  vatReturnPercentage: number;
  setVatReturnPercentage?: (value: number) => void;
  returnDelayMonths?: number | null;
  setReturnDelayMonths?: (value: number | null | undefined) => void;
  selectedCostElementIds: string[];
  setSelectedCostElementIds: (value: string[]) => void;
  selectedUDF: string;
  setSelectedUDF: (value: string) => void;
  selectedUDFLabels: string[];
  setSelectedUDFLabels: (value: string[]) => void;
  type: 'create' | 'edit';
  disabled?: boolean;
}

export const VatReturnInput = ({
  className,
  vatReturnPercentage,
  setVatReturnPercentage,
  returnDelayMonths,
  setReturnDelayMonths,
  selectedCostElementIds,
  setSelectedCostElementIds,
  selectedUDF,
  setSelectedUDF,
  selectedUDFLabels,
  setSelectedUDFLabels,
  type,
  disabled,
}: VatReturnInputProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: userDefinedFieldData, isFetching: isFetchingUDFs } = useApiProjectGetUserDefinedFieldsDefinitionQuery(
    {
      projectId: loadedProjectId ?? 'unset',
    }
  );

  const userDefinedFields = useMemo(
    () => userDefinedFieldData?.userDefinedFieldsDefinition ?? [],
    [userDefinedFieldData],
  );

  const userDefinedFieldsOptions = useMemo(() => {
    const updatedUserDefinedFields = userDefinedFieldData
      ? userDefinedFields
          .filter((field) => field.fieldType === 'List')
          .map((field) => ({ label: field.name, value: field.id }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
    updatedUserDefinedFields.unshift({
      label: t('projectCalculate.NoSelection'),
      value: 'none',
    });

    return updatedUserDefinedFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefinedFieldData]);

  const userDefinedFieldsLabelOptions = useMemo(() => {
    if (selectedUDF === 'none') return [];
    return (
      userDefinedFields
        .find((field) => field.id === selectedUDF)
        ?.listItems?.map((item) => ({ label: item.label, value: item.listItemId }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [selectedUDF, userDefinedFields]);

  const [postGetRestBudget, { isLoading }] = useApiGetCalculationModelRestBudgetMutation();
  const [restBudgetElements, setRestBudgetElements] = useState<CatalogElementRestBudget[]>([])
  useEffect(() => {
    const getRestBudget = async () => {
      const resp = await safeMutation(
        postGetRestBudget,
        {
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? '',
          body: []
        },
        isLoading
      )
      setRestBudgetElements(resp?.payload.catalogElements ?? [])
    }
    try{
      getRestBudget();
    }catch(e){
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const selectedPseudoAssignments: Assignment[] = useMemo(() => {
    return selectedCostElementIds.map((x) => {
      const assignment: Assignment = {
        id: '',
        costElementId: x,
        budget: 0,
        state: 'None'
      }
      return assignment;
    });
  }, [selectedCostElementIds])

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const popoverButtonRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['right-end', 'top'],
        },
      },
    ],
  });

  const flattenedCostElements = useFlattenCostElementRestBudgets(restBudgetElements);

  return (
    <div className={classNames('flex flex-wrap divide-y', className)}>
      {(isLoading || isFetchingUDFs) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      <div
        className={classNames('w-full flex', {
          'flex-col divide-y': type === 'edit',
          'flex-row divide-x': type === 'create',
        })}
      >
        <div
          className={classNames('flex w-1/2 divide-x', {
            'w-full': type === 'edit',
          })}
        >
          <NumberInput
            label={t('projectCalculate.vatReturnModal.vatReturnPercentage')}
            value={vatReturnPercentage}
            onChange={(value) => {
              if (setVatReturnPercentage) setVatReturnPercentage(value ?? 0);
            }}
            icon={<PercentageIcon />}
            disabled={disabled}
          />
          <NumberInput
            label={t('projectCalculate.vatReturnModal.vatReturnDelayMonths')}
            value={returnDelayMonths}
            onChange={(value) => {
              if (setReturnDelayMonths) setReturnDelayMonths(value ?? 0);
            }}
            icon={<StocksIcon />}
            disabled={disabled}
          />
        </div>
        <div
          className={classNames('w-1/2', {
            'w-full': type === 'edit',
          })}
        >
          <Popover>
            <div ref={setTargetElement}>
              <PopoverButton className="w-full flex justify-between bg-white pr-2 h-14">
                <div className="h-14 w-3/4 pl-3 py-1 flex items-center" ref={popoverButtonRef}>
                  <div className="flex items-center h-full">
                    <div className="h-5 w-5 flex items-center justify-center">
                      <EditNodeIcon />
                    </div>
                  </div>
                  <div className="relative ml-2 w-full">
                    <div className="text-left pt-4 w-full text-lg appearance-none focus:outline-none bg-transparent truncate font-medium text-gray-80">
                      {selectedCostElementIds.length > 0
                        ? selectedCostElementIds
                            .map((costElementId) => {
                              const costElement = findCostElementRestBudget(costElementId, flattenedCostElements);
                              return costElement?.name === ''
                                ? t('projectCalculate.unnamedElement')
                                : costElement?.name ?? '';
                            })
                            .join(', ')
                        : t('projectCalculate.NoSelection')}
                    </div>

                    <div className="absolute top-0 left-0 right-0 w-48 text-xs duration-200 origin-0 text-gray-600 select-none transform truncate text-left">
                      {t('projectCalculate.financingElementFinancedCostGroups')}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames('w-1/4 flex items-center justify-end h-full', {
                    'pl-16': type === 'create',
                    'pl-12': type === 'edit',
                  })}
                >
                  <ChevronDownIcon className="w-5 h-5 text-gray-800" />
                </div>
              </PopoverButton>
            </div>
            <PopoverPanel
              portal
              ref={setPopperElement}
              style={{ ...styles.popper }}
              {...attributes.popper}
              className="z-20 flex h-[520px] w-[600px] truncate bg-gray-100 p-4 shadow-lg rounded"
            >
              <CostElementMultiSelect
                assignments={selectedPseudoAssignments}
                updateCostElements={(elementIds) => {
                  setSelectedCostElementIds(elementIds.filter((x) => x.state !== 'Deleted').map((x) => x.costElementId));
                }}
                showVat
                onClose={() => popoverButtonRef.current?.click()}
              />
            </PopoverPanel>
          </Popover>
        </div>
      </div>
      <div
        className={classNames('w-full flex justify-between ', {
          'flex-col divide-y': type === 'edit',
          'flex-row divide-x': type === 'create',
        })}
      >
        <BaseSelect
          label={t('common.userDefinedFields')}
          value={selectedUDF}
          onChange={(value) => setSelectedUDF(value)}
          options={userDefinedFieldsOptions}
          className={classNames('w-1/2', {
            'w-full': type === 'edit',
          })}
          icon={<LoyaltyIcon />}
          disabled={disabled}
        />
        <div
          className={classNames('w-1/2', {
            'w-full': type === 'edit',
          })}
        >
          <MultiSelect
            label={t('common.userDefinedFieldLabels')}
            placeHolder={t('common.userDefinedFieldLabels')}
            options={userDefinedFieldsLabelOptions}
            disabled={selectedUDF === 'none' || disabled}
            value={selectedUDFLabels}
            onChange={(selected) => {
              setSelectedUDFLabels(selected);
            }}
            icon={<EditNodeIcon />}
          />
        </div>
      </div>
    </div>
  );
};
