import { AuthenticateDatevClientResult, DatevClientReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@client/shared/utilities';

interface DatevClientMasterDataCardProps {
  clientData: AuthenticateDatevClientResult | DatevClientReadModel | null;
}

export const DatevClientMasterDataCard = ({ clientData }: DatevClientMasterDataCardProps) => {
  const { t } = useTranslation();

  const earliestFiscalYearStart = clientData?.accountingInformation
    .reduce(
      (earliestDate, current) => {
        const currentStartDate = new Date(current.fiscalYearStart);
        return currentStartDate < earliestDate ? currentStartDate : earliestDate;
      },
      new Date(clientData?.accountingInformation[0].fiscalYearStart),
    )
    .toLocaleDateString();

  const latestFiscalYearEnd = clientData?.accountingInformation
    .reduce(
      (latestDate, current) => {
        const currentEndDate = new Date(current.fiscalYearEnd);
        return currentEndDate > latestDate ? currentEndDate : latestDate;
      },
      new Date(clientData?.accountingInformation[0].fiscalYearEnd),
    )
    .toLocaleDateString();

  return (
    <div className="text-sm flex flex-col gap-3">
      <div className="flex">
        <span className="w-1/2">{t('app.settingsApiDatevConnectedClient')}</span>
        <span className="w-1/2 font-bold">{clientData?.name}</span>
      </div>
      <div className="flex">
        <span className="w-1/2">{t('app.settingsApiDatevConsultantId')}</span>
        <span className="w-1/2 font-bold">
          {clientData && 'datevClientId' in clientData
            ? clientData.datevClientId
            : clientData && 'clientId' in clientData && clientData.clientId}
        </span>
      </div>
      <div className="flex">
        <span className="w-1/2">{t('app.settingsApiDatevBusinessYear')}</span>
        <span className="w-1/2 font-bold">{`${formatDate(earliestFiscalYearStart)} - ${formatDate(
          latestFiscalYearEnd,
        )}`}</span>
      </div>
      {/* <div className="flex">
        <span className="w-1/2">{t('app.settingsApiDatevAccountNumberLength')}</span>
        <span className="w-1/2 font-bold">{clientData?.accountingInformation.length}</span>
      </div> */}
      <div className="flex">
        <span className="w-1/2">{t('app.settingsApiDatevDocumentManagementAvailable')}</span>
        <span className="w-1/2 font-bold">
          {clientData?.isDocumentManagementAvailable ? t('common.yes') : t('common.no')}
        </span>
      </div>
      <div className="flex">
        <span className="w-1/2">{t('app.settingsApiDatevExpirationDate')}</span>
        <span className="w-1/2 font-bold">
          {clientData && 'refreshTokenExpiryDate' in clientData && clientData.refreshTokenExpiryDate}
        </span>
      </div>
      <div className="flex">
        <span className="w-1/2">{t('app.settingsApiDatevConnectedBy')}</span>
        <span className="w-1/2 font-bold">{clientData?.tokenEmittent}</span>
      </div>
    </div>
  );
};
