import classNames from 'classnames';
import React, { Fragment, ReactNode, useId, useMemo, useEffect, useRef } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import {
  Combobox,
  Transition,
  Portal,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxInput,
  Label
} from '@headlessui/react';
import { usePopper } from 'react-popper';
import { isEmpty } from '@client/shared/utilities';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FormHelperText } from './FormHelperText';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export interface ComboSelectOption {
  label: string;
  value: string;
  order?: number;
  disabled?: boolean;
  icon?: ReactNode;
  options?: ComboSelectOption[];
  children?: ReactNode;
}

export interface ComboSelectProps {
  label: string;
  value: string | null;
  options: ComboSelectOption[];
  additionalOption?: ReactNode;
  additionalOptionOnClick?: () => void;
  additionalOptionClassName?: string;
  onChange: (selected: string | null, label?: string | null) => void;
  onBlur?: () => void;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  showValidation?: boolean;
  isValidationValid?: boolean;
  pageOptions?: boolean;
  pageSize?: number;
  helperText?: string;
  children?: ReactNode;
  nullable?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  tabIndex?: number;
  handlePopoverVisibility?: (isOpen: boolean) => void;
  setValue?: (query: string) => void;
  helperTextClassName?: string
}

export const ComboSelect = ({
  className,
  disabled,
  helperText,
  icon,
  isValidationValid,
  label,
  onChange,
  onBlur,
  options,
  additionalOption,
  additionalOptionOnClick,
  additionalOptionClassName = 'sticky bottom-0 bg-white',
  showValidation,
  pageOptions,
  pageSize = 20,
  value,
  children,
  nullable,
  inputRef,
  tabIndex,
  handlePopoverVisibility,
  setValue,
  helperTextClassName
}: ComboSelectProps) => {
  const { t } = useTranslation();

  const inputId = useId();
  const popperElRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const [targetElement, setTargetElement] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const [query, setQuery] = React.useState('');
  const [page, setPage] = React.useState<number>(1);

  const selectedOption = useMemo(() => {
    let foundOption: ComboSelectOption | undefined;
    options.forEach((option) => {
      if (!foundOption) {
        if (option.value === value) {
          foundOption = option;
        } else if (option.options?.length) {
          foundOption = option.options.find((childOption) => childOption.value === value);
        }
      }
    });
    return foundOption ?? null;
  }, [options, value]);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  useEffect(() => {
    if (handlePopoverVisibility) {
      handlePopoverVisibility(!!popperElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  useEffect(() => {
    setQuery('');
    setPage(1);
  }, [value]);

  const filteredOptions = query === '' && !pageOptions ? options : filterOptions(options, query, pageOptions ? page * pageSize : undefined);

  function filterOptions(options: ComboSelectOption[], query: string, elementCount : number | undefined): ComboSelectOption[] {
    let currentCount = 0;
    const filtered: ComboSelectOption[] = [];

    options.forEach((option) => {
      if (elementCount && currentCount >= elementCount){
        return filtered;
      }

      if (option.label.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(option);
        currentCount++;
      } else if (option.options && option.options.length > 0) {
        const nestedOptions = filterOptions(option.options, query, undefined);
        if (nestedOptions.length > 0) {
          const nestedOption: ComboSelectOption = { ...option, options: nestedOptions };
          filtered.push(nestedOption);
          currentCount++;
        }
      }
    });

    return filtered;
  }

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const position = Math.ceil(
        (scrollTop / (scrollHeight - clientHeight)) * 100
    );
    if (position === 100){
      setPage(page + 1)
    }
  }

  const listOptionsWidth = targetElement?.scrollWidth;

  useEffect(() => {
    if (setValue) {
      setValue(query);
    }
  }, [query, setValue]);

  const comboboxOptionsRef = useRef<HTMLDivElement>(null);

  return (
    <div className={className}>
      <div className="w-full relative h-14 bg-white">
        <Combobox
          value={selectedOption}
          onChange={(selected) => onChange(selected?.value ?? null, selected?.label ?? null)}
          disabled={disabled}
          as="div"
          onScroll={handleScroll}
          onBlur={() => {
            onBlur && onBlur();
            setTimeout(() => {
              setQuery('');
              setPage(1);
            }, 100);
          }}
          tabIndex={tabIndex}
        >
          {({ open }) => (
            <>
              <div ref={setTargetElement} id="targetElement">
                <ComboboxButton
                  className={classNames(
                    'w-full relative h-14 px-3 flex flex-row bg-white outline-none peer text-lg ponter-default',
                    {
                      'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
                        showValidation && isValidationValid != null && !isValidationValid,
                      'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
                    },
                  )}
                  ref={buttonRef}
                >
                  {icon && (
                    <div className="flex items-center h-full">
                      <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
                    </div>
                  )}
                  <div
                    className={classNames('relative h-full w-full', {
                      'ml-2': icon,
                      'ml-1': !icon,
                      'cursor-not-allowed': disabled,
                    })}
                  >
                    {label && (
                      <Label
                        htmlFor={inputId}
                        className={classNames(
                          'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate pr-4 text-left',
                          {
                            'pt-3 mt-[3px]': isEmpty(selectedOption) && isEmpty(query),
                            'pt-5 -mt-px text-xs -translate-y-3': !isEmpty(selectedOption) || !isEmpty(query),
                          },
                        )}
                      >
                        {label}
                      </Label>
                    )}
                    <ComboboxInput
                      onKeyDown={e => e.stopPropagation()}
                      onChange={(event) => setQuery(event.target.value)}
                      displayValue={(option: ComboSelectOption) => option?.label ?? ''}
                      className={classNames(
                        'w-full h-full fake-mt block bg-transparent outline-none text-gray-900 font-medium pr-5 truncate',
                        {
                          'pt-0': isEmpty(selectedOption) && isEmpty(query),
                          'pt-3': (!isEmpty(selectedOption) || !isEmpty(query)) && !!label,
                          'text-gray-500': disabled,
                        },
                      )}
                      ref={inputRef}
                      tabIndex={tabIndex}
                    />
                  </div>
                  <div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon className="w-5 h-5 text-gray-800" />
                    </div>
                  </div>
                </ComboboxButton>
                <div
                  className={classNames(
                    'absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-200 transition-opacity peer-focus:opacity-100',
                    {
                      'opacity-0': !open,
                    },
                  )}
                />
              </div>
              <Portal>
                <div
                  onScroll={()=> handleScroll}
                  ref={popperElRef}
                  style={{ ...styles.popper, width: listOptionsWidth }}
                  {...attributes.popper}
                  className="z-50"
                >
                  <Transition
                    show={open}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    beforeEnter={() => setPopperElement(popperElRef.current)}
                    afterLeave={() => setPopperElement(null)}
                    afterEnter={() => {
                      if (comboboxOptionsRef.current) {
                        const inerts = comboboxOptionsRef.current.querySelectorAll('[inert]');
                        if (inerts.length) {
                          inerts.forEach((elem) => elem.removeAttribute('inert'));
                        }
                      }
                    }}
                  >
                    <ComboboxOptions className="origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-bl-lg rounded-br-lg shadow-lg outline-none max-h-72 overflow-y-auto" ref={comboboxOptionsRef}
                      onScroll={handleScroll}
                    >
                      {nullable && selectedOption && (
                        <Fragment key={`select-option-clear`}>
                          <div
                            className="select-none relative py-2 px-3 text-sm cursor-pointer text-center text-gray-900 hover:bg-sky-100 truncate "
                            title={t('app.clearSelection')}
                            onClick={() => {
                              onChange(null);
                              setQuery('');
                              setPage(1);
                              if (buttonRef?.current) {
                                (buttonRef.current as HTMLButtonElement).click();
                              }
                            }}
                          >
                            {t('app.clearSelection')}
                          </div>
                        </Fragment>
                      )}

                      {filteredOptions.map((option, index) => (
                        <Fragment key={`select-option-${index}`}>
                          {!(option.options && option.options.length > 0) ? (
                            <>
                              <ComboboxOption
                                className={({ active }) =>
                                  classNames(
                                    'select-none relative py-3 px-3 text-lg',
                                    { 'cursor-pointer text-gray-900': !active && !option.disabled },
                                    { 'cursor-pointer text-gray-900 bg-sky-100': active && !option.disabled },
                                    { 'cursor-not-allowed text-gray-400 bg-gray-100/50': option.disabled },
                                  )
                                }
                                value={option}
                                disabled={option.disabled}
                              >
                                {({ selected }) => (
                                  <div className="flex gap-2 items-center">
                                    {option.icon}
                                    <span
                                      className={cn('block truncate', {
                                        'font-medium pr-5': selected,
                                      })}
                                      title={option.label}
                                    >
                                      {option.label}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-primary">
                                        <CheckIcon className="w-5 h-5" />
                                      </span>
                                    ) : undefined}
                                  </div>
                                )}
                              </ComboboxOption>
                              {option.children}
                            </>
                          ) : (
                            <>
                              <div className=" relative py-3 px-3 text-lg cursor-default text-gray-500 bg-gray-100/50">
                                <div className="flex gap-2 items-center">
                                  {option.icon}
                                  <span className="block truncate" title={option.label}>
                                    {option.label}
                                  </span>
                                </div>
                              </div>
                              {option.children}
                              {option.options && option.options.length > 0 && (
                                <div className="ml-6">
                                  {option.options.map((childOption, childIndex) => (
                                    <ComboboxOption
                                      key={`select-option-${index}-${childIndex}`}
                                      className={({ active }) =>
                                        classNames(
                                          'select-none relative py-3 px-3 text-lg',
                                          { 'cursor-pointer text-gray-900': !active && !childOption.disabled },
                                          {
                                            'cursor-pointer text-gray-900 bg-sky-100': active && !childOption.disabled,
                                          },
                                          { 'cursor-not-allowed text-gray-400 bg-gray-100/50': childOption.disabled },
                                        )
                                      }
                                      value={childOption}
                                      disabled={childOption.disabled}
                                    >
                                      {({ selected }) => (
                                        <div className="flex gap-2 items-center">
                                          {childOption.icon}
                                          <span
                                            className={cn('block truncate', {
                                              'font-medium pr-5': selected,
                                            })}
                                            title={childOption.label}
                                          >
                                            {childOption.label}
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-primary">
                                              <CheckIcon className="w-5 h-5" />
                                            </span>
                                          ) : undefined}
                                        </div>
                                      )}
                                    </ComboboxOption>
                                  ))}
                                </div>
                              )}
                            </>
                          )}
                        </Fragment>
                      ))}

                      {additionalOption && (
                        <div
                          onClick={additionalOptionOnClick}
                          className={classNames(
                            'py-3 px-3 text-lg cursor-pointer text-gray-900',
                            {
                              'hover:bg-sky-100 cursor-pointer': !disabled,
                            },
                            additionalOptionClassName,
                          )}
                        >
                          {additionalOption}
                        </div>
                      )}
                    </ComboboxOptions>
                  </Transition>
                </div>
              </Portal>
            </>
          )}
        </Combobox>
      </div>
      {helperText && (
        <FormHelperText text={helperText} error={!isValidationValid} className={classNames('w-full', helperTextClassName ?? 'bg-white')} />
      )}
      {children}
    </div>
  );
};
