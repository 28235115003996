import {
  ApiDeleteCostElementApiArg,
  ApiDeleteCostElementApiResponse,
  ApiDeleteCostElementDocumentApiArg,
  ApiDeleteCostElementDocumentApiResponse,
  ApiGetCostElementApiArg,
  ApiGetCostElementApiResponse,
  ApiPostApplyCostElementForecastAsBudgetApiArg,
  ApiPostApplyCostElementForecastAsBudgetApiResponse,
  ApiPostCreateCostElementApiArg,
  ApiPostCreateCostElementApiResponse,
  ApiPostCreateCostElementDocumentApiArg,
  ApiPostCreateCostElementDocumentApiResponse,
  ApiPostCreateVatCostElementApiArg,
  ApiPostCreateVatCostElementApiResponse,
  ApiPostImportCostElementsFileApiArg,
  ApiPostImportCostElementsFileApiResponse,
  ApiPostUpdateCostElementApiArg,
  ApiPostUpdateCostElementApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiCalculateCostEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetCostElement: {
    providesTags: (
      _result: ApiGetCostElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetCostElementApiArg,
    ) => [
      {
        type: ApiTagTypes.CostElement,
        id: args.id,
      },
      { // TODO only that have formula
        type: ApiTagTypes.FormulaElements,
        id: args.calculationModelId,
      },
      {
        type: ApiTagTypes.CalculateElements,
        id: args.calculationModelId,
      },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiDeleteCostElementDocument: {
    invalidatesTags: (
      _result: ApiDeleteCostElementDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCostElementDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.CostElement, id: args.costElementId }] : []),
  },
  apiDeleteCostElement: {
    invalidatesTags: (
      _result: ApiDeleteCostElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCostElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId },
            { type: ApiTagTypes.EmissionYearlyDistributions, id: args.calculationModelId },
            // TODO only invalidate the elements that have a reference in formula to the cost element
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostCreateCostElementDocument: {
    invalidatesTags: (
      _result: ApiPostCreateCostElementDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCostElementDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.CostElement, id: args.costElementId }] : []),
  },
  apiPostCreateCostElement: {
    invalidatesTags: (
      _result: ApiPostCreateCostElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCostElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostImportCostElementsFile: {
    invalidatesTags: (
      _result: ApiPostImportCostElementsFileApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostImportCostElementsFileApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostUpdateCostElement: {
    invalidatesTags: (
      _result: ApiPostUpdateCostElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCostElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CostElement, id: args.id },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
            { type: ApiTagTypes.UserDefinedFields, id: args.id },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId },
            // TODO only invalidate the elements that have a reference in formula to the cost element
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostApplyCostElementForecastAsBudget: {
    invalidatesTags: (
      _result: ApiPostApplyCostElementForecastAsBudgetApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostApplyCostElementForecastAsBudgetApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CostElement, id: args.costElementId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId }
          ]
        : [],
  },
  apiPostCreateVatCostElement: {
    invalidatesTags: (
      _result: ApiPostCreateVatCostElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateVatCostElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Report, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId }
          ]
        : [],
  },
};
