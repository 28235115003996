import classNames from 'classnames';
import React, { ChangeEvent, ReactNode, useEffect } from 'react';
import { createFileList, formatFileSize } from '@client/shared/utilities';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '../icons';

interface FileInputProps {
  acceptedFileTypes: string[];
  multiple: boolean;
  className?: string;
  uploadDescription?: string | ReactNode;
  icon?: React.ReactNode;
  selectedFiles?: FileList | null;
  onChange: (fileList: FileList | null) => void;
}

export const FileInput = ({
  acceptedFileTypes,
  className,
  icon,
  multiple,
  uploadDescription,
  selectedFiles,
  onChange,
}: FileInputProps) => {
  const { t } = useTranslation();

  const [fileList, setFileList] = React.useState<FileList | null | undefined>(selectedFiles);
  const files = fileList ? Array.from(fileList) : [];

  useEffect(() => {
    setFileList(selectedFiles);
  }, [selectedFiles])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target as HTMLInputElement;

    if (files) {
      setFileList(files);
    }

    if (onChange) {
      onChange(files);
    }
  };

  const removeFile = (index: number) => {
    const files = fileList ? Array.from(fileList) : [];
    files.splice(index, 1);

    const newFileList = createFileList(...files);
    setFileList(newFileList);

    if (onChange) {
      onChange(newFileList);
    }
  };

  const loadPreview = (file: File) => {
    return URL.createObjectURL(file);
  };

  return (
    <div
      className={classNames(
        'relative flex flex-col text-gray-400 rounded cursor-pointer hover:bg-gray-100 py-8 transition-colors duration-300',
        className
      )}
    >
      <input
        className="absolute inset-0 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
        multiple={multiple}
        accept={acceptedFileTypes.join(',')}
        type="file"
        onClick={(event)=> {
          // allow selecting file again after removing
          event.currentTarget.value = ''
        }}
        onChange={handleChange}
        onDrop={(event) => {
          event.preventDefault();
          const files = event.dataTransfer.files;
          handleChange({ target: { files } } as ChangeEvent<HTMLInputElement>);
        }}
      />

      <div className="flex flex-col items-center justify-center text-center text-medium">
        {icon ? icon : <DocumentIcon className="w-10 h-10" />}
        <p className="mt-6">{uploadDescription ? uploadDescription : t('app.fileUploadMessage')}</p>
      </div>

      {files.length > 0 && (
        <div className="absolute inset-0 flex flex-row space-x-2 overflow-x-auto items-center px-6">
          {files.map((file, index) => (
            <div
              key={index}
              className="relative w-24 flex flex-col flex-shrink-0 items-center overflow-hidden text-center bg-gray-100 border rounded select-none"
            >
              <div
                className="absolute top-0 right-0 z-50 p-1 bg-white rounded-bl cursor-pointer"
                onClick={() => removeFile(index)}
              >
                <TrashIcon className="w-4 h-4 text-gray-700" />
              </div>
              <div className="flex items-center justify-center w-20 h-20">
                {file.type.includes('image/') ? (
                  <img className="inline-block" src={loadPreview(file)} alt="Preview" />
                ) : (
                  <DocumentIcon className="w-6 h-6 text-gray-400" />
                )}
              </div>
              <div className="w-full flex flex-col p-2 text-xs bg-white bg-opacity-50">
                <span className="w-full font-bold text-gray-900 truncate">{file.name}</span>
                <span className="text-xs text-gray-900">{formatFileSize(file.size)}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
