import {
  ApiAccessReadModel,
  DocuWareProjectMappingPayload,
  DocuWareProjectMappingReadModel,
  useApiGetProjectsQuery,
} from '@client/shared/api';
import { AddButton, BaseSelectOption, List } from '@client/shared/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocuwareProjectMappingListItem } from './DocuwareProjectMappingListItem';

interface DocuwareClientSlideOverProjectMappingsTabProps {
  readModel: ApiAccessReadModel | null | undefined;
  updateProjectMappings: (mappings: DocuWareProjectMappingPayload[]) => void;
}

export const DocuwareClientSlideOverProjectMappingsTab = ({
  readModel,
  updateProjectMappings,
}: DocuwareClientSlideOverProjectMappingsTabProps) => {
  const { t } = useTranslation();

  const { data } = useApiGetProjectsQuery();

  const [mappings, setMappings] = useState<DocuWareProjectMappingReadModel[]>([]);

  const options = useMemo(() => {
    return (
      (data?.projects?.map((project) => {
        return {
          label: project.name,
          value: project.id,
        };
      }) as BaseSelectOption[]) ?? []
    ).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }, [data?.projects]);

  useEffect(() => {
    setMappings(readModel?.docuWareAccessConfiguration?.projectMappings ?? []);
  }, [readModel]);

  useEffect(() => {
    updateProjectMappings(mappings as DocuWareProjectMappingPayload[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappings]);

  return (
    <div>
      {readModel ? (
        <div className="flex flex-col gap-3">
          <List
            className="mt-10"
            listTitle={t('app.settingsApiDocuwareProjectMappings')}
            items={mappings.map((item, index) => {
              return {
                id: `mapping-${index}`,
                children: (
                  <DocuwareProjectMappingListItem
                    id={index}
                    options={options.filter((option) =>
                      option.value === item.probisProjectId
                        ? true
                        : !mappings.some((mapping) => mapping.probisProjectId === option.value) &&
                          option.value !== item.probisProjectId,
                    )}
                    probisProject={item.probisProjectId}
                    docuWareProject={item.docuWareProject}
                    onUpdate={(id, probisProject, docuWareProject) => {
                      const updatedMappings = [...mappings];
                      updatedMappings[index] = {
                        probisProject: '',
                        probisProjectId: probisProject ?? '',
                        docuWareProject: docuWareProject ?? '',
                      };
                      setMappings(updatedMappings);
                    }}
                    onDelete={(id) => {
                      setMappings(mappings.filter((_, index) => index !== id));
                    }}
                  />
                ),
              };
            })}
            showPagination={false}
          />
          {mappings.length < options.length && (
            <div className="w-full justify-end">
              <AddButton
                className="float-right"
                disabled={mappings.length === options.length}
                onClick={() => {
                  setMappings([...mappings, { probisProject: '', probisProjectId: '', docuWareProject: '' }]);
                }}
              />
            </div>
          )}
        </div>
      ) : (
        t('app.settingsApiDocuwareNoMasterDataAvailable')
      )}
    </div>
  );
};
