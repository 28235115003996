import { useTranslation } from 'react-i18next';
import {
  ApiAccessReadModel,
  useApiPostStartDocuWareProcessMutation,
} from '@client/shared/api';
import { Button } from '@client/shared/toolkit';

interface DocuwareClientSlideOverMasterDataTabProps {
  apiAccessId: string;
  readModel: ApiAccessReadModel | null | undefined;
}

export const DocuwareClientSlideOverMasterDataTab = ({
  apiAccessId,
  readModel,
}: DocuwareClientSlideOverMasterDataTabProps) => {
  const { t } = useTranslation();

  const [docuwareImport] = useApiPostStartDocuWareProcessMutation();

  const startImport = async () => {
    try {
      await docuwareImport().unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {readModel ? (
        <div className="text-sm flex flex-col gap-3">
          <div className="flex">
            <span className="w-1/2">{t('app.ConnectApiUrl')}</span>
            <span className="w-1/2 font-bold">{readModel?.apiUrl}</span>
          </div>
          <div className="flex">
            <span className="w-1/2">{t('app.ConnectUserName')}</span>
            <span className="w-1/2 font-bold">{readModel?.userName}</span>
          </div>
          <div className="flex">
            <span className="w-1/2">{t('app.settingsApiDocuwareProjectIdentifier')}</span>
            <span className="w-1/2 font-bold">{readModel?.docuWareAccessConfiguration?.projectIdentifier}</span>
          </div>
          <div className="flex">
            <span className="w-1/2">{t('app.settingsApiDocuwareDialog')}</span>
            <span className="w-1/2 font-bold">{readModel?.docuWareAccessConfiguration?.dialogLabel}</span>
          </div>
          <Button variant="primary" className="mt-2" onClick={startImport}>
            {t('app.settingsApiDocuwareStartImport')}
          </Button>
        </div>
      ) : (
        t('app.settingsApiDatevNoMasterDataAvailable')
      )}
    </div>
  );
};
