import { useLoadedProject, useLoadedVariantId } from '@client/project/store';
import {
  Assignment,
  BudgetAssignmentReadModel,
  CatalogElementRestBudget,
  CreateBudgetAssignmentPayload,
  UpdateBudgetAssignmentPayload,
  useApiGetCalculationModelRestBudgetMutation,
} from '@client/shared/api';
import { SlideOverTitle, AddButton, LoadingIndicator } from '@client/shared/toolkit';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useFlattenCostElementRestBudgets } from '../../hooks';
import { findCostElementRestBudget } from '../../utils';
import { BudgetAssignmentInput, CostElementMultiSelect, FormattedCurrency } from '..';
import cn from 'classnames';
import { safeMutation } from '@client/shared/utilities';

interface BudgetAssignmentProps {
  budgetAssignments?: BudgetAssignmentReadModel[] | undefined;
  updateBudgetAssignments?: ({
    added,
    updated,
    deleted,
    totalBudget,
  }: {
    added: CreateBudgetAssignmentPayload[];
    updated: UpdateBudgetAssignmentPayload[];
    deleted: string[];
    totalBudget: number;
  }) => void;
  validBudget?: (valid: boolean) => void;
  canBeBudgeted?: boolean;
  disabled?: boolean;
  showTitle?: boolean;
  id?: string;
  setIsBudgetAssignmentPopoverOpen?: (isOpen: boolean) => void;
  cannotBeBudgetedMessage?: string | ReactNode;
  focused?: boolean;
  selectedBudget?: string;
}

export const BudgetAssignment = ({
  budgetAssignments = [],
  updateBudgetAssignments,
  validBudget,
  canBeBudgeted = true,
  disabled = false,
  showTitle = true,
  setIsBudgetAssignmentPopoverOpen,
  cannotBeBudgetedMessage,
  focused = false,
  selectedBudget,
}: BudgetAssignmentProps) => {
  const { t } = useTranslation();

  const project = useLoadedProject();
  const projectId = project.currentData?.project.payload.id;
  const calculateValueType = project.currentData?.project.payload.calculateValueType;
  const variantId = useLoadedVariantId();

  const [postGetRestBudget, { isLoading }] = useApiGetCalculationModelRestBudgetMutation();
  const [restBudgetElements, setRestBudgetElements] = useState<CatalogElementRestBudget[]>([])
  const [workAssignments, setWorkAssignments] = useState<Assignment[]>(budgetAssignments.map((x) => {
    const assignment: Assignment = {
      id: x.id,
      costElementId: x.costElementId,
      budget: calculateValueType === 'Net' ? x.budgetNet : x.budgetGross,
      state: 'None'
    }
    return assignment;
  }));

  useEffect(() => {
    const getRestBudget = async () => {
      const resp = await safeMutation(
        postGetRestBudget,
        {
          projectId: projectId ?? '',
          calculationModelId: variantId ?? '',
          body: workAssignments
        },
        isLoading
      )

      setRestBudgetElements(resp?.payload.catalogElements ?? [])
    }

    try {
      getRestBudget();
    } catch(e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[workAssignments])

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const popoverButtonRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-end', 'left', 'auto'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const [budget, setBudget] = useState<number>(0);
  const flattenedCostElements = useFlattenCostElementRestBudgets(restBudgetElements);

  const getCostElement = useCallback(
    (id: string) => {
      return findCostElementRestBudget(id, flattenedCostElements);
    },
    [flattenedCostElements],
  );

  const getCostElementCode = (costElementId: string): string => {
    const costElement = getCostElement(costElementId);
    if (costElement && 'code' in costElement) {
      return costElement.code ?? '';
    }
    else return '';
  };

  const getCostElementDescription = (costElementId: string): string => {
    const costElement = getCostElement(costElementId);
    return costElement?.name === '' ? t('projectCalculate.unnamedElement') : costElement?.name ?? '';
  };

  useEffect(() => {
    setBudget(
      workAssignments.filter((x) => x.state !== 'Deleted').reduce((sum, budgetAssignment) => { return sum + budgetAssignment.budget;}, 0),
    );
  }, [workAssignments]);

  useEffect(() => {
    if (validBudget) {
      if(disabled){
        validBudget(true);
        return;
      }

      const valid = !workAssignments.some((budgetAssignment) => {
        const assignemntCostElement = getCostElement(budgetAssignment.costElementId);

        if((assignemntCostElement?.values.budget ?? 0) >= 0)
          return (assignemntCostElement?.values.restBudget ?? 0) < 0;
        else
          return (assignemntCostElement?.values.restBudget ?? 0) > 0;
      })

      validBudget(valid);
    }

  }, [workAssignments, disabled, validBudget, budget, getCostElement]);

  useEffect(() => {
    updateBudgetAssignments?.({
      added: workAssignments
        .filter((x) => x.state === 'Added')
        .map((y) => {
          return {
            costElementId: y.costElementId,
            budget: y.budget
          }
        }),
      updated: workAssignments
        .filter((x) => x.state === 'Updated')
        .map((y) => {
          return {
            budgetAssignmentId: y.id ?? '',
            budget: y.budget,
          };
        }),
      deleted: workAssignments
        .filter((x) => x.state === 'Deleted')
        .map((y) => y.id),
      totalBudget: budget,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workAssignments, budget]);

  useEffect(() => {
    if (setIsBudgetAssignmentPopoverOpen && popperElement) {
      setIsBudgetAssignmentPopoverOpen(true);
    } else if (setIsBudgetAssignmentPopoverOpen && !popperElement) {
      setIsBudgetAssignmentPopoverOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  return (
    <>
      {isLoading && <LoadingIndicator text={t('projectContract.budgetingLoadingCostElements') ?? ''} mode="overlay" />}

      {showTitle && <SlideOverTitle title={t('projectContract.budgeting')} />}
      {canBeBudgeted ? (
        <>
          <div
            className={cn('mt-0 bg-white relative', {
              'min-h-[56px] before:content-[""] before:absolute before:left-0 before-top-0 before:w-1 before:h-full before:bg-slate-600 before:transition-opacity before:duration-300 before:z-10':
                !workAssignments.length,
              'before:opacity-1': focused && !workAssignments.length,
              'before:opacity-0': !focused && !workAssignments.length,
            })}
          >
            <div
              className={classNames(
                'divide-slate-300 divide-y-2 relative before:content-[""] before:absolute before:left-0 before-top-0 before:w-1 before:h-full before:bg-slate-600 before:transition-opacity before:duration-300 before:z-10',
                {
                  'border-b-2': workAssignments.length > 0,
                  'before:opacity-1': focused,
                  'before:opacity-0': !focused,
                },
              )}
            >
              {workAssignments
                .sort((a, b) => getCostElementCode(a.costElementId).localeCompare(getCostElementCode(b.costElementId)))
                .filter((x) => x.state !== 'Deleted')
                .map((budgetAssignment, i) => {
                  const assignmentCostElement = getCostElement(budgetAssignment.costElementId);
                  return (
                    <BudgetAssignmentInput
                      key={`budgetAssignment-${i}`}
                      name={getCostElementDescription(budgetAssignment.costElementId)}
                      code={getCostElementCode(budgetAssignment.costElementId)}
                      budgetSum={assignmentCostElement?.values.budget ?? 0}
                      budgetRest={assignmentCostElement?.values.restBudget ?? 0}
                      hasFormula={getCostElement(budgetAssignment.costElementId)?.values.hasFormula ?? false}
                      disabled={disabled}
                      value={budgetAssignment.budget}
                      onChange={(value) => {
                        setWorkAssignments((prev) => {
                          return prev.map((x) => {
                            if (x.costElementId === budgetAssignment.costElementId && x.budget !== value) {
                              if(x.state === 'Added'){
                                return { ...x, budget: value };
                              }
                              return { ...x, budget: value, state: 'Updated' };
                            } else return x;
                          });
                        });
                      }}
                      showValidation={!disabled}
                      onDelete={() => {
                        setWorkAssignments((prev) => {
                          const asgns: Assignment[] = []
                          for(const asg of prev){
                            if (asg.costElementId === budgetAssignment.costElementId) {
                              if(asg.state !== 'Added'){
                                asgns.push({...asg, state: 'Deleted'})
                              }
                            } else asgns.push(asg);
                          }
                          return asgns;
                        });
                      }}
                      active={
                        !!selectedBudget &&
                        !!budgetAssignment.id &&
                        selectedBudget === budgetAssignment.id
                      }
                    />
                  );
                })}
            </div>
          </div>
          {!disabled && (
            <Popover>
              <div className="flex w-full justify-end items-center z-50 relative -mt-3 mr-4">
                <PopoverButton>
                  <div ref={setTargetElement}>
                    <div ref={popoverButtonRef}>
                      <AddButton />
                    </div>
                  </div>
                </PopoverButton>
              </div>
              <PopoverPanel
                portal
                ref={setPopperElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
                className="z-20 flex h-[520px] w-[600px] truncate bg-gray-100 p-4 shadow-lg rounded"
              >
                <CostElementMultiSelect
                  assignments={workAssignments}
                  updateCostElements={(assignments) => {setWorkAssignments(assignments)}}
                  showBudget
                  showFx
                  showControls
                  onlyWithCostElementId
                  onClose={() => popoverButtonRef.current?.click()}
                />
              </PopoverPanel>
            </Popover>
          )}
          <div className="flex flex-col items-end pr-12 -mt-3 pt-2 pb-4">
            <div className="border-y-2 border-slate-300 h-2 w-44 max-w-full mb-2" />
            <div className="text-xs text-slate-600">{t('projectContract.budgetSum')}</div>
            <div className="text-2xl font-bold">
              <FormattedCurrency amount={budget} />
            </div>
          </div>
        </>
      ) : (
        <div className="w-full flex items-center text-xs text-slate-600 mb-6">
          {cannotBeBudgetedMessage ? cannotBeBudgetedMessage : t('projectContract.connectedElementBudgeted')}
        </div>
      )}
    </>
  );
};
