import {
  CalculationModelDeliveryPhaseReadModelExt,
  getMinMonthSize,
  isTimelineElementInYear,
  TimeLineBodyGroupProps,
  TimeLineDataContext,
  TimeLineElementFinance,
  TimeLinePositionedContainer,
  TimeLineView,
} from '../index';
import { CalculationModelMilestoneReadModel } from '@client/shared/api';
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { endOfMonth, parseISO, startOfMonth } from 'date-fns';
import cn from 'classnames';
import { TimeLineBodyElement } from './TimeLineBodyElement';
import { TimeLineBarChart } from './TimeLineBarChart';
import { formatDateOnly } from '@client/shared/utilities';
import { TimeLineBodyFinanceRows } from './TimeLineBodyFinanceRows';
import { ELEMENT_ROW_HEIGHT } from '../../utils';

export interface TimeLineBodyYearProps {
  months: Date[];
  view: TimeLineView;
  phases?: CalculationModelDeliveryPhaseReadModelExt[];
  milestones?: CalculationModelMilestoneReadModel[];
  groups?: TimeLineBodyGroupProps[];
  expandedIds?: string[][];
  containerOpen: { [key: string]: boolean };
  dueDate?: string | null;
  maxHeight?: number;
  firstYear: boolean;
}
export const TimeLineBodyYear = memo((props: TimeLineBodyYearProps) => {
  const {
    months = [],
    view,
    phases = [],
    milestones = [],
    groups = [],
    expandedIds = [],
    containerOpen ,
    dueDate,
    maxHeight,
    firstYear
  } = props;
  const {
    toggledYears,
    isHeaderCollapsed,
    activeMilestone,
    setActiveMilestone,
    activePhase,
    setActivePhase,
    dragScrollContainerRef
  } = useContext(TimeLineDataContext);

  const today = useMemo(() => {
    return new Date();
  }, [])
  const year = useMemo(() => {
    return months.length ? months[0].getFullYear() : today.getFullYear();
  }, [months, today]);
  const isToggled = useMemo(() => {
    return toggledYears.includes(year);
  }, [toggledYears, year]);

  const style = useMemo(() => {
    return getMinMonthSize(view, months.length, isToggled);
  }, [isToggled, months.length, view]);

  const timelineStartDate = useMemo(() => {
    const start = startOfMonth(months[0]);
    return new Date(start.toDateString()); // get rid of time
  }, [months]);
  const timelineEndDate = useMemo(() => {
    const end = endOfMonth(months[months.length - 1]);
    return new Date(end.toDateString()); // get rid of time
  }, [months]);

  const updateActivePhase = useCallback(
    (code: string | null) => {
      const dragScrollContainerRefCurrent = dragScrollContainerRef?.current
        // updating dragging in context was not performant...
        if(!dragScrollContainerRefCurrent?.getAttribute('dragging')) {
          setActivePhase(code);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dragScrollContainerRef?.current, setActivePhase]
  );
  const updateActiveMilestone = useCallback(
    (code: string | null) => {
      // updating dragging in context was not performant...
      if(!dragScrollContainerRef?.current?.getAttribute('dragging')) {
        setActiveMilestone(code);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dragScrollContainerRef?.current, setActiveMilestone]
  );

  const dueDateParsed = useMemo(() => {
    if (dueDate) {
      const date = parseISO(dueDate)
      if (date.getFullYear() === year) {
        return date
      }
    }
    return null
  }, [dueDate, year])

  if (!months.length) return null;
  return (
    <div
      data-year={year}
      className={cn(
        'relative overflow-visible overflow-y-visible overflow-x-visible w-auto min-h-[64px]',
        'before:content-[""] before:absolute before:w-[2px] before:h-full before:top-0 before:left-0 before:bg-gray-200 before:z-10',
        {
          // 'flex-auto': !isToggled,
          'flex-none': isToggled || months.length <= 2
        }
      )}
      style={{...style}}
    >
      {/* Due Date Marker */}
      {dueDateParsed && (
        <TimeLinePositionedContainer
          timeLineStartDate={timelineStartDate}
          timeLineEndDate={timelineEndDate}
          date={dueDateParsed}
          className="h-full"
        >
          <div className="border-l border-l-lime-500 h-full border-dashed" />
        </TimeLinePositionedContainer>
      )}
      {/* Today Marker */}
      {year === today.getFullYear() && (
        <TimeLinePositionedContainer
          timeLineStartDate={timelineStartDate}
          timeLineEndDate={timelineEndDate}
          date={today}
          className="h-full"
        >
          <div className="border-l border-l-amber-400 h-full border-dashed" />
        </TimeLinePositionedContainer>
      )}
      {/* Phases */}
      {!isToggled &&
        !isHeaderCollapsed &&
        phases.map((phase) => (
          <TimeLinePositionedContainer
            key={`timeline-body-phase-${phase.code}`}
            timeLineStartDate={timelineStartDate}
            timeLineEndDate={timelineEndDate}
            date={phase.yearStart}
            endDate={phase.endDate}
            className={cn('h-full w-full', {
              'z-40': activePhase === phase.code,
            })}
          >
            <div
              className={cn('w-full relative h-full', {
                'before:content-[""] before:top-0 before:absolute before:-left-px before:pointer-events-auto before:cursor-pointer before:h-full before:border-l before:transition-colors before:duration-300 before:w-5':
                  phase.startDate && phase.startDate >= timelineStartDate,
                'after:content-[""] after:top-0 after:absolute after:-right-px after:pointer-events-auto after:cursor-pointer after:h-full after:border-r after:transition-colors after:duration-300 after:w-5':
                  phase.endDate && phase.endDate <= timelineEndDate,
                'before:border-secondary after:border-secondary': activePhase === phase.code,
                'before:border-slate-300 after:border-slate-300': activePhase !== phase.code,
              })}
              onMouseEnter={() => updateActivePhase(phase.code)}
              onMouseLeave={() => updateActivePhase(null)}
            />
          </TimeLinePositionedContainer>
        ))}
      {/* Milestones */}
      {!isToggled &&
        !isHeaderCollapsed &&
        milestones.length > 0 &&
        milestones.map((milestone) => (
          <TimeLinePositionedContainer
            key={`timeline-body-milestone-${year}-${milestone.code}`}
            timeLineStartDate={timelineStartDate}
            timeLineEndDate={timelineEndDate}
            date={milestone.date}
            className={cn('h-full pointer-events-auto cursor-pointer', {
              'z-40': activeMilestone === milestone.code,
            })}
          >
            <div
              className="relative h-full cursor-pointer w-5 -translate-x-1/2"
              onMouseEnter={() => updateActiveMilestone(milestone.code)}
              onMouseLeave={() => updateActiveMilestone(null)}
            >
              <div
                className={cn('absolute top-0 left-1/2 border-l h-full border-dashed transition-colors duration-300', {
                  'border-l-secondary': activeMilestone === milestone.code,
                  'border-l-gray-300': activeMilestone !== milestone.code,
                })}
              />
            </div>
          </TimeLinePositionedContainer>
        ))}
      {/* Elements per group (costs, risks, etc.) */}
      <TimeLineBodyYearGroups
        groups={groups}
        expandedIds={expandedIds}
        view={view}
        timelineStartDate={timelineStartDate}
        timelineEndDate={timelineEndDate}
        year={year}
        containerOpen={containerOpen}
        maxHeight={maxHeight}
        firstYear={firstYear}
        isToggled={isToggled}
        months={months}
        dueDate={dueDate}
      />
    </div>
  );
});

interface TimeLineBodyYearGroupsProps {
  groups: TimeLineBodyGroupProps[];
  expandedIds?: string[][];
  view: TimeLineView;
  timelineStartDate: Date;
  timelineEndDate: Date;
  year: number;
  containerOpen: { [key: string]: boolean };
  maxHeight?: number
  firstYear: boolean
  isToggled?: boolean
  months: Date[];
  dueDate?: string | null;
}
export const TimeLineBodyYearGroups = memo((props: TimeLineBodyYearGroupsProps) => {
  const {
    groups,
    expandedIds = [],
    view,
    timelineStartDate,
    timelineEndDate,
    year,
    containerOpen,
    maxHeight,
    firstYear,
    isToggled = false,
    months,
    dueDate
  } = props;
  const isOpen = useCallback((name: string | undefined) => {
    return containerOpen && name ? containerOpen[name] : true
  }, [containerOpen])
  if (!groups.length) return null;
  return (
    <>
      {groups.length > 0 && (
        <div className="w-full pointer-events-auto">
          {groups.map((group, i) => {
            return (
              <TimeLineBodyYearGroup
                key={`timeline-body-group-${group.name}`}
                group={group}
                year={year}
                timelineStartDate={timelineStartDate}
                timelineEndDate={timelineEndDate}
                view={view}
                expandedIds={expandedIds[i]}
                open={isOpen(group.name)}
                maxHeight={maxHeight}
                firstYear={firstYear}
                isToggled={isToggled}
                months={months}
                dueDate={dueDate}
              />
            );
          })}
        </div>
      )}
    </>
  );
});

interface TimeLineBodyYearGroupProps {
  group: TimeLineBodyGroupProps;
  expandedIds?: string[];
  year: number;
  view: TimeLineView;
  timelineStartDate: Date;
  timelineEndDate: Date;
  open?: boolean;
  maxHeight?: number
  firstYear: boolean
  isToggled?: boolean
  months: Date[];
  dueDate?: string | null;
}
export const TimeLineBodyYearGroup = ({
  group,
  expandedIds = [],
  year,
  view,
  timelineStartDate,
  timelineEndDate,
  open = true,
  maxHeight,
  firstYear,
  isToggled,
  months,
  dueDate
}: TimeLineBodyYearGroupProps) => {
  const collapsibleRef = useRef<HTMLDivElement>(null)
  const [collapsibleRefTop, setCollapsibleRefTop] = useState(0)
  const offset = 100
  // check vertical visibility
  const isItemVisible = useCallback((idx: number) => {
    if (collapsibleRef.current && maxHeight && expandedIds && view) {
      const elemPos = ELEMENT_ROW_HEIGHT * idx + collapsibleRefTop // collapsibleRef.current.getBoundingClientRect().top
      return elemPos <= maxHeight - offset
    }
    return false
  }, [maxHeight, expandedIds, view, collapsibleRefTop])

  const barChartDataForYear = useMemo(() => {
    if (group.barChartData) {
      if (isToggled) {
        let isValuesOfYear = 0
        let shouldValuesOfYear = 0
        group.barChartData.elements.forEach((data) => {
          const date = parseISO(data.date)
          if (date && date.getFullYear() === year) {
            isValuesOfYear += data.datedPayments?.paymentSumNet ?? 0
            shouldValuesOfYear += data.plan?.value ?? 0
          }
        })
        return [{
          date: formatDateOnly(new Date(year)),
          isValue: isValuesOfYear,
          shouldValue: shouldValuesOfYear
        }]
      }
      return months.map((month) => {
        const foundData = group.barChartData?.elements.find((data) => {
          const date = parseISO(data.date)
          return date && date.getFullYear() === year && date.getMonth() === month.getMonth()
        })
        return {
          date: formatDateOnly(month),
          isValue: foundData?.datedPayments?.paymentSumNet ?? 0,
          shouldValue: foundData?.plan?.value ?? 0
        }
      })
    }
    return []
  }, [year, group.barChartData, isToggled, months])

  useEffect(() => {
    if (collapsibleRef.current) {
      setCollapsibleRefTop(collapsibleRef.current.getBoundingClientRect().top)
    }
  }, [open, collapsibleRef]);

  const chartIsVisible = useMemo(() => {
    if (group.barChartData && group.barChartData?.elements.length > 0 && view === TimeLineView.TIMELINE) {
      if (group.elements?.length) {
        return isItemVisible(group.elements.length - 1);
      }
     return true;
    }
    return false;
  }, [group.barChartData, view, group.elements?.length, isItemVisible])

  return (
    <div
      className={cn('flex flex-col w-full relative pointer-events-none', {
        'mb-[120px] min-h-[60px]': open,
        'h-16': !open,
        'before:content-[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:min-w-full before:min-h-full before:bg-white before:-z-10': open,
        'pb-3': !(group.barChartData && group.barChartData?.elements.length > 0 && view === TimeLineView.TIMELINE)
      })}
      ref={collapsibleRef}
    >
      {group.elements &&
        group.elements.length > 0 &&
        group.elements.map((data, idx) => {
          if (!isItemVisible(idx)) return null
          return (
            <React.Fragment key={`timeline-body-element-${group.name}-${idx}`}>
              {/* ---- TIMELINE ROW ----*/}
              <div
                data-id={data.id}
                data-parent-id={data.parentId}
                data-element-id={data.elementId}
                className={cn('h-[38px] w-full pointer-events-auto', {
                  'mt-8': !data.level,
                })}
              >
                {view === TimeLineView.TIMELINE &&
                  data.timelineElement &&
                  isTimelineElementInYear(year, data.timelineElement.startDate, data.timelineElement.endDate) && (
                    <TimeLineBodyElement
                      year={year}
                      data={data}
                      view={view}
                      timelineStartDate={timelineStartDate}
                      timelineEndDate={timelineEndDate}
                      expandedIds={expandedIds}
                      paymentToDate={data.financeTimeline?.paymentToDateNet}
                      plannedToDate={data.financeTimeline?.plannedToDate}
                      totalPayment={data.financeTimeline?.totalPaymentNet}
                      totalPlanned={data.financeTimeline?.totalPlanned}
                      dueDate={dueDate}
                    />
                  )}
                {view === TimeLineView.FINANCE &&
                  data.financeTimeline &&
                  isTimelineElementInYear(year, data.financeTimeline.start, data.financeTimeline.end) && (
                    <TimeLineElementFinance
                      timeLineStartDate={timelineStartDate}
                      timeLineEndDate={timelineEndDate}
                      financeTimeline={data.financeTimeline}
                      year={year}
                      timeLineElement={data}
                      group={group.name}
                    />
                  )}
              </div>
              {/* ---- FINANCE ROWS ----*/}
              {view === TimeLineView.FINANCE && (
                <TimeLineBodyFinanceRows
                  timelineStartDate={timelineStartDate}
                  timelineEndDate={timelineEndDate}
                  data={data}
                  year={year}
                  groupName={group.name ?? ''}
                  idx={idx}
                  expandedIds={expandedIds}
                />
              )}
            </React.Fragment>
          );
        })}
      {chartIsVisible && (
        <TimeLineBarChart
          barChartData={barChartDataForYear}
          first={firstYear}
          groupName={group.name}
          isToggled={isToggled}
          months={months}
        />
      )}
    </div>
  );
};
