import React, { PropsWithChildren, useMemo } from 'react';
import { CheckBox, DocumentIcon, DocumentViewerFileDataSet, Highlighted, ListItem, SortDownIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@client/shared/utilities';
import cn from 'classnames';
import { ShortInvoiceReadModel } from '@client/shared/api';
import { FormattedCurrency, getDueDateMessage } from '@client/project/shared';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export interface InvoiceListItemProps extends PropsWithChildren {
  invoice: ShortInvoiceReadModel;
  showNet?: boolean;
  searchValue?: string
  customIcon?: React.ReactNode;
}

export const InvoiceListItem = (props: InvoiceListItemProps) => {
  const {
    invoice,
    children,
    showNet = true,
    searchValue = '', 
    customIcon
  } = props;

  const { t } = useTranslation()

  const invoiceStateLabel = useMemo(() => {
    switch (invoice.state) {
      case 'Approved':
        return t('projectControl.stateApproved')
      case 'Pending':
        return t('projectControl.statePending')
      case 'Paid':
        return t('projectControl.statePaid')
      case 'Audited':
        return t('projectControl.stateAudited')
      default:
        return ''
    }
  }, [invoice.state, t])

  return (
    <div className="flex flex-wrap w-full md:divide-x">
      <div className="w-full md:w-4/12 flex-none flex gap-y-3.5 justify-between md:pr-3 pb-3 md:pb-0">
        <div className="flex gap-3.5 items-center">
          {customIcon ? customIcon : (
            <DocumentIcon className="hidden md:block flex-none" />
          )}
          {invoice.isPxInvoice &&
            <ArrowPathIcon className="w-6 h-6 text-sky-900"/>
          }
          <div>
            <div className="font-bold text-[15px]">
              {searchValue ? <Highlighted text={invoice.code} highlight={searchValue} /> : invoice.code}
            </div>
            <div className="flex flex-wrap text-[11px] text-gray-500 leading-none">
              {invoice.externalCode && (
                <div className="font-bold">
                  {searchValue ? <Highlighted text={invoice.externalCode} highlight={searchValue} /> : invoice.externalCode}
                  &nbsp;
                </div>
              )}
              {typeof invoice.externalCode !== 'undefined' && invoice.externalCode ? <>&nbsp;•&nbsp;</> : ''}
              {invoice.sequentialNumber ? (
                <>
                  {searchValue ? (
                    <>
                      {t('projectContract.numberLabel')}
                      <Highlighted text={invoice.sequentialNumber.toString()} highlight={searchValue} />
                    </>) : `${t('projectContract.numberLabel')} ${invoice.sequentialNumber}`}
                </>
              ) : ''}
              {typeof invoice.state !== 'undefined' ? <>&nbsp;•&nbsp;</> : ''}
              {invoiceStateLabel ? <>&nbsp;<Highlighted text={invoiceStateLabel} highlight={searchValue} /></>: ''}
              {invoice.isPxInvoice ? <>&nbsp;•&nbsp;{t('projectControl.importedInvoice')}</> : ''}
            </div>
          </div>
        </div>
        {invoice.dateOfReceipt && (
          <DocumentViewerFileDataSet
            className="text-right"
            subtitle={formatDate(invoice.dateOfReceipt)}
            text={invoice.dueDate ? getDueDateMessage(invoice.dueDate) : ''}
          />
        )}
      </div>
      <div className="border-t md:border-t-0 flex-none grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:pl-3 md:w-8/12 pt-3 md:pt-0 w-full gap-4">
        <div className="hidden lg:flex flex-col justify-center">
          <div className="font-bold text-[15px] truncate">
            {invoice.contractName ? <Highlighted text={invoice.contractName} highlight={searchValue} /> : ''}
          </div>
          <div className="flex flex-wrap text-[11px] text-gray-500 leading-none font-bold">
            {invoice.contractCode ? <Highlighted text={invoice.contractCode} highlight={searchValue} /> : ''}
          </div>
        </div>
        <div className="hidden xl:flex flex-col text-[15px] leading-tight text-right justify-center">
          <FormattedCurrency amount={invoice.claim} />
          <span className="md:hidden text-[10px] text-gray-500">
            {t('projectControl.invoiceClaim')}
          </span>
          <span className="md:hidden text-[10px] text-gray-500" />
        </div>
        <div className="flex flex-col text-[15px] leading-tight text-right justify-center">
          {showNet ? <FormattedCurrency amount={invoice.invoiceValueNet} /> : <FormattedCurrency amount={invoice.invoiceValueGross} />}
          <span className="hidden md:block text-[10px] text-gray-500">
            {showNet ? t('projectControl.netAmount') : t('projectControl.grossAmount')}
          </span>
          <span className="md:hidden text-[10px] text-gray-500">
            {t('projectControl.InvoiceAmountLabel')}
          </span>
        </div>
        <div className="flex flex-col text-[15px] leading-tight text-right justify-center">
          {showNet ? <FormattedCurrency amount={invoice.retentionNet} /> : <FormattedCurrency amount={invoice.retentionGross} />}
          <span className="hidden md:block text-[10px] text-gray-500">
            {showNet ? t('projectControl.netAmount') : t('projectControl.grossAmount')}
          </span>
          <span className="md:hidden text-[10px] text-gray-500">
            {t('projectControl.RetentionLabel')}
          </span>
        </div>
        <div className="flex flex-col text-[15px] leading-tight text-right justify-center">
          {showNet ? <FormattedCurrency amount={invoice.paymentValueNet} /> : <FormattedCurrency amount={invoice.paymentValueGross} />}
          <span className="hidden md:block text-[10px] text-gray-500">
            {showNet ? t('projectControl.netAmount') : t('projectControl.grossAmount')}
          </span>
          <span className="md:hidden text-[10px] text-gray-500">
            {t('projectControl.PaymentReleaseLabel')}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
};

export interface InvoiceListItemSumProps extends PropsWithChildren {
  showNet?: boolean;
  netValue: number;
  grossValue: number;
  paymentNet: number;
  paymentGross: number;
  retentionGross: number;
  retentionNet: number;
}

export const InvoiceListItemSum = (props: InvoiceListItemSumProps) => {
  const { t } = useTranslation();
  const {
    showNet = true,
    // netValue,
    // grossValue,
    paymentNet,
    paymentGross,
    retentionGross,
    retentionNet
  } = props;
  return (
    <ListItem
      borderColor="bg-transparent"
      bgColor=""
      shadow={false}
      additionalContent={(<div className="w-0 md:w-[56px] h-full" />)}
      as="div"
    >
      <div className="flex flex-wrap justify-end w-full mt-2">
        <div className="flex-none grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:pl-3 md:w-8/12 pt-3 md:pt-0 w-full gap-4">
          <span className="hidden lg:block font-bold">&nbsp;</span>
          {/* Contract */}
          <div className="flex flex-col leading-none text-right" />
          {/* Claim */}
          <div className="hidden xl:flex flex-col" />
          <div className="flex flex-col leading-none text-right text-gray-500">
            <span className="text-xl leading-none font-bold text-primary">
              {showNet ? <FormattedCurrency amount={retentionNet} /> : <FormattedCurrency amount={retentionGross} />}
            </span>
            <span className="hidden md:block text-[10px]">
              {t('projectControl.total')}
            </span>
            <span className="md:hidden text-[10px] text-gray-500">
              {t('projectControl.invoiceTotal')}
            </span>
          </div>
          <div className="flex flex-col leading-none text-right text-gray-500">
            <span className="text-xl leading-none font-bold text-primary">
              {showNet ? <FormattedCurrency amount={paymentNet} /> : <FormattedCurrency amount={paymentGross} />}
            </span>
            <span className="hidden md:block text-[10px]">
              {t('projectControl.total')}
            </span>
            <span className="md:hidden text-[10px] text-gray-500">
              {t('projectControl.paymentTotal')}
            </span>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export interface InvoiceListSortHeaderProps extends PropsWithChildren {
  onHandleSort: (index: number) => void
  sortValues: (boolean | null)[],
  checkedStatus?: boolean;
  setCheckedStatus?: (status: boolean) => void;
}

export const InvoiceListSortHeader = (props: InvoiceListSortHeaderProps) => {
  const { t } = useTranslation();
  const { children, onHandleSort, sortValues, checkedStatus, setCheckedStatus } = props;

  // TODO translations
  const leftHeader = useMemo(() => {
    return [
      t('projectControl.ContractorAndInvoiceNumberLabel'),
      t('projectControl.ReceiptAndDueLabel')
    ]
  }, [t])

  const rightHeader = useMemo(() => {
    return [
      t('projectControl.ContractLabel'),
      t('projectControl.invoiceClaim'),
      t('projectControl.InvoiceAmountLabel'),
      t('projectControl.RetentionLabel'),
      t('projectControl.PaymentReleaseLabel')
    ]
  }, [t])

  return (
    <ListItem
      className="hidden sm:flex"
      borderColor="bg-transparent"
      bgColor=""
      shadow={false}
      padding={false}
      additionalContent={(<div className="w-0 md:w-[56px] h-full" />)}
      as="div"
    >
      <div className="flex flex-wrap w-full md:divide-x pt-2 text-[13px] text-gray-500">
        <div className="w-full md:w-4/12 flex-none flex gap-y-3.5 justify-between md:pr-3 pb-3 md:pb-0 truncate">
          <div className="flex">
            {setCheckedStatus && (
              <CheckBox
                checked={checkedStatus}
                onChange={(checked) => {
                  setCheckedStatus(checked);
                }}
                className="hidden md:block mr-2 cursor-pointer"
              />
            )}
            {leftHeader.map((label, i) => (
              <InvoiceListSortHeaderItem
                className={i === 0 ? 'hidden md:flex' : i === 1 ? 'hidden xl:flex' : undefined}
                key={`invoice-sort-header-left-${i}`}
                label={label}
                onClick={() => onHandleSort(i)}
                asc={sortValues[i]}
                alignRight={false}
              />
            ))}
          </div>
        </div>
        <div className="flex-none grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:pl-3 md:w-8/12 pt-3 md:pt-0 w-full gap-4">
          {rightHeader.map((label, i) => (
            <InvoiceListSortHeaderItem
              className={i === 0 ? 'hidden lg:flex' : i === 1 ? 'hidden xl:flex' : undefined}
              key={`invoice-sort-header-right-${i}`}
              label={label}
              onClick={() => onHandleSort(i + leftHeader.length)}
              asc={sortValues[i + leftHeader.length]}
              alignRight={i !== 0}
            />
          ))}
        </div>
        {children}
      </div>
    </ListItem>
  );
};

export interface InvoiceListSortHeaderItemProps {
  className?: string
  onClick: () => void
  label: string
  asc: boolean | null
  alignRight?: boolean
}

export const InvoiceListSortHeaderItem = (props: InvoiceListSortHeaderItemProps) => {
  const {
    className,
    onClick,
    label,
    asc,
    alignRight = true
  } = props
  return (
    <span className={cn('flex gap-2.5 items-center cursor-pointer', alignRight ? 'justify-end' : '', className)} onClick={onClick}>
      {label}
      <SortDownIcon className={cn('flex-none w-7',
        {
            'rotate-180': asc || asc === null,
            'opacity-30': asc === null
          }
        )}
      />
    </span>
  )
}
