import {
  isTimelineElementInYear,
  TimeLineElementFinanceContract, TimeLineElementFinanceData,
  TimeLineElementsProps
} from '.';
import React, { useMemo } from 'react';

type TimeLineBodyFinanceRowsProps = {
  data: TimeLineElementsProps
  timelineStartDate: Date
  timelineEndDate: Date
  year: number
  groupName: string
  expandedIds: string[]
  idx: number
}

export const TimeLineBodyFinanceRows = (props: TimeLineBodyFinanceRowsProps) => {
  const {
    data,
    timelineStartDate,
    timelineEndDate,
    year,
    groupName,
    expandedIds,
    idx
  } = props

  const rows: { [key: string]: TimeLineElementFinanceData[] } = useMemo(() => {
    let commitmentRows: TimeLineElementFinanceData[] = []
    let contractRows: TimeLineElementFinanceData[] = []
    let invoicingPartyRows: TimeLineElementFinanceData[] = []

    /* COMMITMENTS */
    if (data.commitments && data.commitments.length) {
      commitmentRows = data.commitments.map((commitment) => {
        return {
          datedPayments: commitment?.datedPayments,
          budget: commitment?.budget,
          forecast: commitment?.forecast,
          payment: commitment?.payment,
        }
      })
    }

    /* CONTRACTS */
    if (data.contracts && data.contracts.length) {
      contractRows = data.contracts.map((contract) => {
        return {
          datedPayments: contract?.datedPayments,
          budget: contract?.budget,
          forecast: contract?.forecast,
          payment: contract?.payment
        }
      })
    }

    /* INVOICING PARTIES */
    if (data.invoicingParties && data.invoicingParties.length) {
      invoicingPartyRows = data.invoicingParties.map((invoicingParty) => {
        return {
          datedPayments: invoicingParty?.datedPayments,
          budget: invoicingParty?.budget,
          forecast: invoicingParty?.forecast,
          payment: invoicingParty?.payment
        }
      })
    }

    return {
      commitment: commitmentRows,
      contract: contractRows,
      'invoicing-party': invoicingPartyRows
    }
  }, [data.commitments, data.contracts, data.invoicingParties])

  return (
    <>
      {/* REST ROW */}
      {data.restBudget && data.elementId &&
        ((data.type === 'element' && data.parentId && expandedIds?.includes(data.parentId))
          || (data.type === 'group' && expandedIds?.includes(data.id ?? data.elementId))) && (
          <div data-rest="true" data-element-id={data.elementId} className="h-[38px] w-full" />
        )}
      {/* CONTRACT ROWS */}
      {Object.keys(rows).map((key, index) => {
        return (
          <TimeLineBodyFinanceContractRows
            key={`tl-contract-rows-${key}`}
            rows={rows[key]}
            timelineStartDate={timelineStartDate}
            timelineEndDate={timelineEndDate}
            data={data}
            year={year}
            groupName={groupName}
            idx={idx}
            expandedIds={expandedIds}
            type={key}
          />
        )
      })}
    </>
  )
}

export interface TimeLineBodyFinanceContractRowsProps extends TimeLineBodyFinanceRowsProps {
  rows: TimeLineElementFinanceData[]
  type: string
}

export const TimeLineBodyFinanceContractRows = (props: TimeLineBodyFinanceContractRowsProps) => {
  const {
    data,
    rows,
    expandedIds,
    type,
    groupName,
    idx,
    timelineStartDate,
    timelineEndDate,
    year
  } = props

  const isInYear = useMemo(() => {
    if (data.financeTimeline) {
      return isTimelineElementInYear(year, data.financeTimeline.start, data.financeTimeline.end)
    }
    return false
  }, [data.financeTimeline, year])

  return (
    <>
      {rows.length > 0 && rows.map((row, cIdx) => {
        if (data.elementId && ((data.type === 'element' && data.parentId && expandedIds?.includes(data.parentId))
          || (data.type === 'group' && expandedIds?.includes(data.id ?? data.elementId)))) {
          return (
            <div
              className="relative h-[38px] w-full z-30 pointer-events-auto"
              data-finance-element-id={data.elementId}
              data-row-id={row.id}
              data-row-type={type}
              key={`timeline-body-element-${groupName}-${idx}-${type}-${cIdx}`}
            >
              {isInYear && data && (
                <TimeLineElementFinanceContract
                  timeLineStartDate={timelineStartDate}
                  timeLineEndDate={timelineEndDate}
                  financeTimeline={data.financeTimeline}
                  data={row}
                  year={year}
                />
              )}
            </div>
          )
        }
        return null
      })}
    </>
  )
}
