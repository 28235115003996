import {
  Avatar,
  Button,
  FileInput,
  Form,
  FormField, GlobeIcon, LoadingIndicator, Modal, OrganizationIcon, SecurityPassIcon,
  SlideOver,
  SlideOverOnCloseProps, TaskCompletedIcon,
  TextInput,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import * as yup from 'yup';
import { InferType } from 'yup';
import {
  ApiPostCreateCompanyApiArg,
  ApiPostUpdateCompanyApiArg,
  CompanyReadModel,
  useApiPostCreateCompanyMutation,
  useApiPostUpdateCompanyMutation,
} from '@client/shared/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDeleteModal } from '.';

const CompanyFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  taxId: yup.string().optional(),
  companyRegistrationNumber: yup.string().optional(),
  link: yup.string().optional(),
});

type CompanyFormValidationValues = InferType<typeof CompanyFormValidationSchema>;

interface PartnerCompanyEditSlideOverProps extends SlideOverOnCloseProps {
  company?: CompanyReadModel | undefined;
}

export const PartnerCompanyEditSlideOver = (props: PartnerCompanyEditSlideOverProps) => {
  const { company, onClose } = props;
  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);
  const [postCreateCompany, { isLoading: isCreating }] = useApiPostCreateCompanyMutation();
  const [postUpdateCompany, { isLoading: isUpdating }] = useApiPostUpdateCompanyMutation();

  const [showDeleteCompany, setShowDeleteCompany] = useState(false)

  const defaultValues = {
    name: company?.name ?? '',
    taxId: company?.taxId ?? '',
    companyRegistrationNumber: company?.companyRegistrationNumber ?? '',
    link: company?.link ?? '',
  };

  const handleFileChange = (fileList: FileList | null) => {
    if (fileList) {
      setFile(fileList[0]);
    }
  };

  const handleSubmit = async (data: CompanyFormValidationValues) => {
    const payload = new FormData();
    payload.append('name', data.name ?? '');
    payload.append('taxId', data.taxId ?? '');
    payload.append('companyRegistrationNumber', data?.companyRegistrationNumber ?? '');
    payload.append('link', data?.link ?? '');
    if (file) {
      payload.append('logo', file);
    }

    if (!company?.id) {
      try {
        await safeMutation(
          postCreateCompany,
          {
            body: payload,
          } as ApiPostCreateCompanyApiArg,
          isCreating,
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await safeMutation(
          postUpdateCompany,
          {
            companyId: company?.id ?? '',
            body: payload,
          } as ApiPostUpdateCompanyApiArg,
          isUpdating,
        );

        onClose(false);
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <>
      {(isCreating || isUpdating) && (
        <LoadingIndicator text={t('app.settingsCompaniesLoading') ?? ''} mode="overlay" />
      )}
      <Form<CompanyFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={CompanyFormValidationSchema}
        defaultValues={defaultValues}
        className="flex flex-col flex-grow min-h-0"
      >
        <SlideOver.Header
          className="flex-end"
          title={company?.id ? company?.name ?? '' : t('app.companiesAddCompany')}
          subTitle={t('app.masterDataPartnerCompany')}
          backgroundClassName="bg-slate-800"
          onClose={onClose}
          additionalContentBefore={company ? (
            <Avatar
              name={company.name}
              className="w-[60px] h-[60px] mb-2"
              rounded={false}
              randomBgColor={false}
              imagePath={company.logoFileId ? `/api/companies/${company.id}/logo/${company.logoFileId}` : undefined}
            />
          ) : undefined}
          truncateTitle={false}
        />
        <SlideOver.Content className="p-8">
          <FileInput
            className="bg-white rounded-sm w-full h-40 p-5"
            acceptedFileTypes={['image/jpeg']}
            multiple={false}
            onChange={(files) => handleFileChange(files)}
            uploadDescription={t('app.companiesUploadLogo')}
          />
          <FormField name="name">
            {(control) => <TextInput icon={<OrganizationIcon />} label={t('app.companiesName')} {...control} />}
          </FormField>
          <FormField name="taxId">
            {(control) => <TextInput icon={<SecurityPassIcon />} label={t('app.companiesTaxId')} {...control} />}
          </FormField>
          <FormField name="companyRegistrationNumber">
            {(control) => <TextInput icon={<TaskCompletedIcon />} label={t('app.masterDataCompanyRegistrationNumber')} {...control} />}
          </FormField>
          <FormField name="link">
            {(control) => <TextInput icon={<GlobeIcon />} label={t('app.masterDataCompanyWebsite')} {...control} />}
          </FormField>
        </SlideOver.Content>

        <SlideOver.Controls>
          {company?.id && (
            <Button variant="danger" className="mr-2" onClick={() => setShowDeleteCompany(true)}>
              {t('common.delete')}
            </Button>
          )}
          <div className="w-full flex justify-end">
            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.cancel')}
            </Button>
            <Button variant="primary" formSubmit={true}>
              {company?.id ? t('common.save') : t('common.create')}
            </Button>
          </div>
        </SlideOver.Controls>
      </Form>
      <Modal isOpen={showDeleteCompany} onClose={() => setShowDeleteCompany(false)}>
        <CompanyDeleteModal
          company={company}
          onClose={() => {
            setShowDeleteCompany(false)
            onClose(true)
          }}
        />
      </Modal>
    </>
  );
};
