import {
  Button,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTabOptions,
  SlideOverWithTabs,
} from '@client/shared/toolkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatevClientSlideOverMasterDataTab, DatevClientSlideOverProjectsTab, DatevWizard } from '.';
import {
  useApiDatevClientTokenRevokeMutation,
  useApiDeleteDatevApiAccessMutation,
  useApiGetDatevApiAccessQuery,
} from '@client/shared/api';
import { DatevClientResetModal } from './DatevClientResetModal';
import { safeMutation } from '@client/shared/utilities';
import { DatevClientSlideOverMappingTab } from '@client/project/shared';
import toast from 'react-hot-toast';
import { DatevAuthState, updateDatevAuthData, useDatevAuthState } from '@client/project/store';

interface DatevClientSlideOverProps extends SlideOverOnCloseProps {
  apiId: string;
  apiAccessId: string;
  isOpen: boolean;
}

export const DatevClientSlideOver = ({ isOpen, onClose, apiAccessId, apiId }: DatevClientSlideOverProps) => {
  const { t } = useTranslation();

  const [isResetClientModalOpen, setIsResetClientModalOpen] = useState(false);
  const [isDatevWizardOpen, setIsDatevWizardOpen] = useState(false);
  const [modalType, setModalType] = useState<'delete' | 'reset'>('delete');

  const [deleteClient, { isLoading: isDeleting, isSuccess: isDeleted }] = useApiDeleteDatevApiAccessMutation();
  const [postRevokeDatevClientToken, { isLoading: isRevokingClientToken }] = useApiDatevClientTokenRevokeMutation();

  const datevAuthState = useDatevAuthState();

  const { data, isFetching } = useApiGetDatevApiAccessQuery(
    {
      apiAccessId: apiAccessId,
    },
    {
      skip: isDeleting || isDeleted || !apiAccessId,
    },
  );

  const tabOptions: SlideOverTabOptions[] = [
    {
      header: t('app.settingsApiDatevMasterData'),
      name: 'masterDate',
      panel: (
        <div className="w-full h-full">
          {isFetching ? (
            <LoadingIndicator text={t('app.settingsApiDatevLoadingClient') ?? ''} mode="overlay" />
          ) : (
            <DatevClientSlideOverMasterDataTab apiAccessId={apiAccessId} clientData={data?.clientDetail} />
          )}
        </div>
      ),
    },

    {
      header: t('app.settingsApiDatevConnections'),
      name: 'connections',
      panel: <DatevClientSlideOverMappingTab apiAccessId={apiAccessId} connectionData={data} />,
    },
    {
      header: t('app.settingsApiDatevProjects'),
      name: 'projects',
      panel: <DatevClientSlideOverProjectsTab connectionData={data} />,
    },
  ];

  const handleReset = () => {
    updateDatevAuthData({
      datevTenantAuthData: undefined,
      datevClientId: undefined,
      datevClientAuthData: undefined,
      datevUpdateData: undefined,
      datevWizardType: 'reset',
      datevApiAccessId: apiAccessId,
    } as DatevAuthState);
    setIsResetClientModalOpen(false);
    setIsDatevWizardOpen(true);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteClient,
        {
          apiAccessId: apiAccessId,
        },
        isDeleting,
      );
      setIsResetClientModalOpen(false);
      onClose(false);
    } catch (e) {
      console.log(e);
    }
  };

  const revokeDatevClientToken = async () => {
    if (data?.clientDetail?.id) {
      try {
        await safeMutation(
          postRevokeDatevClientToken,
          {
            clientId: data.clientDetail.id,
          },
          isRevokingClientToken,
        );
        toast.success(t('app.ConnectRevokeTokenSuccess'));
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <SlideOverWithTabs
        tabOptions={tabOptions}
        title={t('app.settingsApiDatevConnection')}
        subtitle={t('app.settingsApiDatevConnectionDescription')}
        onClose={onClose}
      >
        <SlideOver.Controls className="w-full flex justify-between">
          <div className="flex ">
            <Button
              variant="danger"
              className="mr-2"
              onClick={() => {
                setModalType('delete');
                setIsResetClientModalOpen(true);
              }}
            >
              {t('common.delete')}
            </Button>
            <Button
              variant="warning"
              className="mr-2"
              onClick={() => {
                setModalType('reset');
                setIsResetClientModalOpen(true);
              }}
            >
              {t('common.reset')}
            </Button>
            {data?.clientDetail?.hasAccessAvailable && (
              <Button variant="primary" onClick={() => revokeDatevClientToken()} className="mx-2">
                {t('app.ConnectRevokeClientToken')}
              </Button>
            )}
          </div>
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.close')}
          </Button>
        </SlideOver.Controls>
      </SlideOverWithTabs>
      <DatevClientResetModal
        isOpen={isResetClientModalOpen}
        onClose={() => setIsResetClientModalOpen(false)}
        handleReset={handleReset}
        handleDelete={handleDelete}
        clientName={data?.clientDetail?.name ?? ''}
        isDeleting={isDeleting}
        modalType={modalType}
      />
      {isDatevWizardOpen && (
        <DatevWizard
          isOpen={isDatevWizardOpen}
          onClose={() => setIsDatevWizardOpen(false)}
          apiId={apiId}
          apiAccessId={apiAccessId}
          isReset={datevAuthState?.datevWizardType === 'reset'}
        />
      )}
    </>
  );
};
