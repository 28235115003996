import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import {
  ExportedInvoicesResponse,
  ShortInvoiceReadModel,
  useApiExportInvoicesToDatevMutation,
  useApiGetProjectDatevConnectionQuery,
} from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { BaseSelect, Button, CheckBox, LoadingIndicator, Modal, ModalOnCloseProps } from '@client/shared/toolkit';
import { formatDateOnly } from '@client/shared/utilities';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

interface DatevExportModalProps extends ModalOnCloseProps {
  selectedInvoices: ShortInvoiceReadModel[];
  isOpen: boolean;
  type: 'datevExport' | 'datevLog';
}

export const DatevExportModal = ({ onClose, isOpen, selectedInvoices, type }: DatevExportModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [invoices, setInvoices] = useState<ShortInvoiceReadModel[]>([]);

  useEffect(() => {
    setInvoices(selectedInvoices);
  }, [selectedInvoices]);

  const [exportInvoices, { isLoading: isExporting }] = useApiExportInvoicesToDatevMutation();
  const { data: datevData, isFetching: isFetchingDatev } = useApiGetProjectDatevConnectionQuery({
    projectId: loadedProjectId ?? '',
  });

  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [response, setResponse] = useState<ExportedInvoicesResponse | null>(null);

  const handleSubmit = async () => {
    try {
      exportInvoices({
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedCalculationModelId ?? '',
        body: {
          invoiceIds: invoices.map((invoice) => invoice.id),
          selectedClientId: datevData?.clientDetail?.id ?? '',
          accountingMonth: formatDateOnly(new Date(Number(selectedYear), Number(selectedMonth), 1)),
        },
      })
        .unwrap()
        .then((data) => {
          setResponse(data);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const yearOptions = useMemo(() => {
    const yearSet = new Set<string>();
    datevData?.clientDetail?.accountingInformation.forEach(({ fiscalYearStart }) => {
      const year = String(new Date(fiscalYearStart).getFullYear());
      yearSet.add(year < '2000' ? '2000' : year);
    });
    return Array.from(yearSet).map((year) => ({ value: year, label: year }));
  }, [datevData?.clientDetail?.accountingInformation]);

  const monthOptions = useMemo(() => {
    const year = datevData?.clientDetail?.accountingInformation.find(
      (accountingInformation) => new Date(accountingInformation.fiscalYearStart).getFullYear() === Number(selectedYear),
    );
    const fiscalYearStart = year ? new Date(year.fiscalYearStart).getMonth() : new Date().getMonth();
    const fiscalYearEnd = year ? new Date(year.fiscalYearEnd).getMonth() : new Date().getMonth();

    return [
      { value: '0', label: t('common.monthJanuary'), disabled: fiscalYearStart > 0 && fiscalYearEnd < 0 },
      { value: '1', label: t('common.monthFebruary'), disabled: fiscalYearStart > 1 && fiscalYearEnd < 1 },
      { value: '2', label: t('common.monthMarch'), disabled: fiscalYearStart > 2 && fiscalYearEnd < 2 },
      { value: '3', label: t('common.monthApril'), disabled: fiscalYearStart > 3 && fiscalYearEnd < 3 },
      { value: '4', label: t('common.monthMay'), disabled: fiscalYearStart > 4 && fiscalYearEnd < 4 },
      { value: '5', label: t('common.monthJune'), disabled: fiscalYearStart > 5 && fiscalYearEnd < 5 },
      { value: '6', label: t('common.monthJuly'), disabled: fiscalYearStart > 6 && fiscalYearEnd < 6 },
      { value: '7', label: t('common.monthAugust'), disabled: fiscalYearStart > 7 && fiscalYearEnd < 7 },
      { value: '8', label: t('common.monthSeptember'), disabled: fiscalYearStart > 8 && fiscalYearEnd < 8 },
      { value: '9', label: t('common.monthOctober'), disabled: fiscalYearStart > 9 && fiscalYearEnd < 9 },
      { value: '10', label: t('common.monthNovember'), disabled: fiscalYearStart > 10 && fiscalYearEnd < 10 },
      { value: '11', label: t('common.monthDecember'), disabled: fiscalYearStart > 11 && fiscalYearEnd < 11 },
    ];
  }, [datevData?.clientDetail?.accountingInformation, t, selectedYear]);

  useEffect(() => {
    setSelectedYear(String(datevData?.accountingMonth ? new Date(datevData?.accountingMonth).getFullYear() : ''));
  }, [datevData?.accountingMonth, yearOptions]);

  useEffect(() => {
    setSelectedMonth(String(datevData?.accountingMonth ? new Date(datevData?.accountingMonth).getMonth() : ''));
  }, [datevData?.accountingMonth, monthOptions]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="max">
      <Modal.Header
        title={type === 'datevLog' ? t('projectControl.datevLogTitle') : t('projectControl.datevExportTitle')}
        description={
          type === 'datevLog'
            ? t('projectControl.datevExportLogDescription')
            : t('projectControl.datevExportDescription')
        }
      />
      <Modal.Content className="w-full flex justify-start items-center">
        {(isExporting || isFetchingDatev) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
        {!response && type !== 'datevLog' ? (
          <>
            {!datevData?.clientDetail ? (
              <div className="h-full flex flex-col justify-center items-center">
                <div className="p-2 mb-2 text-center">{t('projectControl.datevExportNoClientConfigured')}</div>
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate({
                      pathname: ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.path.replace(':id', loadedProjectId ?? ''),
                      search: createSearchParams({ tab: 'connections' }).toString(),
                    })
                  }
                >
                  {t('app.projectSettings')}
                </Button>
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full flex divide-x-2">
                  <BaseSelect
                    className="w-full"
                    label={t('common.year')}
                    options={yearOptions}
                    value={selectedYear}
                    onChange={(value) => setSelectedYear(value)}
                  />
                  <BaseSelect
                    className="w-full"
                    label={t('common.month')}
                    options={monthOptions}
                    value={selectedMonth}
                    onChange={(value) => setSelectedMonth(value)}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <div className="p-5 font-bold text-xl text-center">
                    {t('projectControl.datevExportSelectedInvoices')}
                  </div>

                  <div className="w-2/3 flex flex-col items-center h-80 overflow-auto bg-white p-2 divide-y">
                    {selectedInvoices.map((invoice) => (
                      <div key={invoice.id} className="w-full flex items-center justify-between p-1">
                        <span className="ml-2">{invoice.code}</span>
                        <CheckBox
                          className="mx-5 cursor-pointer"
                          checked={invoices.includes(invoice)}
                          onChange={() => {
                            if (invoices.includes(invoice)) {
                              setInvoices(invoices.filter((i) => i.id !== invoice.id));
                            } else {
                              setInvoices([...invoices, invoice]);
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="w-full h-80 text-slate-800">
            {response?.csvHeaderError && response?.csvHeaderError?.length > 0 && (
              <>
                <div className="flex font-bold mt-2 bg-white px-2 pt-2">
                  <span className="w-40">{t('projectControl.datevExportCsvHeader')}</span>
                  <span>{t('projectControl.datevExportError')}</span>
                </div>
                <div className="p-2 w-full overflow-auto bg-white divide-y">
                  <ul className="ml-40 overflow-auto gap-3 flex flex-col">
                    {response?.csvHeaderError?.map((error, i) => (
                      <li key={`${error.code}-${i}`}>
                        {t(
                          `${error.code}`.toLowerCase(),
                          error.messageParameters?.reduce(
                            (acc: Record<string, string>, curr: string, index: number) => {
                              acc[index.toString()] = curr;
                              return acc;
                            },
                            {},
                          ) ?? {},
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            {response?.invoiceResponse && (
              <>
                <div className="flex font-bold mt-2 bg-white p-2">
                  <span className="w-40">{t('projectControl.datevExportInvoice')}</span>
                  <span className="w-40">{t('projectControl.datevExportStatus')}</span>
                  <span className="flex-1" >{t('projectControl.datevExportError')}</span>
                </div>
                <div className="p-2 w-full overflow-auto bg-white divide-y">
                  {response?.invoiceResponse.map((item, i) => (
                    <div key={item.id} className="flex">
                      <span className="w-40 font-bold">{invoices.find((invoice) => invoice.id === item.id)?.code}</span>
                      <span className="w-40">{t(`projectControl.datevExportStatus${item.status}`)}</span>
                      <ul className="flex-1 flex flex-col gap-3" >
                        {item?.invoiceWithErrors &&
                          item?.invoiceWithErrors .map((error, i) => (
                            <li key={`${error.code}-${i}`} className="flex">
                              {t(
                                `${error.code}`.toLowerCase(),
                                error.messageParameters?.reduce(
                                  (acc: Record<string, string>, curr: string, index: number) => {
                                    acc[index.toString()] = curr;
                                    return acc;
                                  },
                                  {},
                                ) ?? {},
                              )}
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {response ? t('common.close') : t('common.cancel')}
        </Button>
        {type === 'datevExport' && !response && (
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={
              isExporting ||
              !datevData?.clientDetail ||
              !selectedYear ||
              !selectedMonth ||
              invoices.length === 0 ||
              !!response
            }
          >
            {t('projectControl.datevExportSend')}
          </Button>
        )}
      </Modal.Controls>
    </Modal>
  );
};
