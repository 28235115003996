import {
  Badge,
  BaseSelect,
  BaseSelectOption,
  BaseSelectOptionInfoTooltip,
  Breadcrumb,
  BreadcrumbItemProps,
  CheckBox,
  CheckDollarIcon,
  ComboSelect,
  ComboSelectOption,
  DatePicker,
  DecoratedCard,
  DecoratedCardContent,
  DecoratedCardHeader,
  DescriptionSection,
  DescriptionSectionBlock,
  DocumentViewerFileDataInlineEdit,
  ErrorIcon,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  HelpingHandFilledIcon,
  HintBox,
  LoadingIndicator,
  MultiSelect,
  NumberInput,
  PaidBillFilledIcon,
  SliderControlFilledIcon,
  // SliderPagination,
  TextInput
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import {
  RequirementUpdateFormValidationSchema,
  RequirementUpdateFormValidationValues,
} from './RequirementFormValidationSchemas';
import {
  RequirementCategory,
  RequirementCustomFieldType,
  RequirementReadModel,
  RequirementShortReadModel,
  UpdateRequirementCustomFieldPayload,
  useApiGetClusterItemsQuery,
  useApiGetClusterItemsTreeQuery,
  useApiPostUpdateClusterItemRequirementMutation,
} from '@client/shared/api';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { formatDate, formatDateOnly, formatNumber, safeMutation } from '@client/shared/utilities';
import { CalendarDaysIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useUi } from '@client/shared/store';
import {
  checkIsReporter,
  getClusterItemUsers,
  getOrgaUnitNamesForUser,
  getReporterNameById,
  getRequirementStatusBadge,
} from '@client/portfolio/shared';
import { getClusterListItems } from './ClusterItemSelect';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useNavigate } from 'react-router-dom';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';

export const FRAPORT_CUSTOM_FIELD_REF_NAME =
  'Schnittstellen zu anderen Bedarfen, Konzepten, Projektleitdokumenten, Projekten';

export const FRAPORT_COLLAPSIBLE_ITEMS = ['Zieltermin 2', 'Erläuterung Zieltermin 2', 'Zieltermin 3', 'Erläuterung Zieltermin 3'];

export const FRAPORT_CUSTOM_FIELDS: UpdateRequirementCustomFieldPayload[] = [
  // Management Summary
  {
    name: 'Bedeutung für den Geschäfts-Prozess des Bedarfmelders',
    type: 'Text',
    category: 'Management Summary',
    required: true,
  },
  {
    name: 'Zieltermin der Umsetzung',
    type: 'Date',
    category: 'Management Summary',
    required: true,
  },
  {
    name: 'Erläuterung Zieltermin',
    type: 'Text',
    category: 'Management Summary',
    required: true,
  },
  {
    name: 'Zieltermin 2',
    type: 'Date',
    category: 'Management Summary',
  },
  {
    name: 'Erläuterung Zieltermin 2',
    type: 'Text',
    category: 'Management Summary',
  },
  {
    name: 'Zieltermin 3',
    type: 'Date',
    category: 'Management Summary',
  },
  {
    name: 'Erläuterung Zieltermin 3',
    type: 'Text',
    category: 'Management Summary',
  },
  // Ausführliche Beschreibungen
  {
    name: 'Ausgangssituation Ist-Beschreibung',
    type: 'Text',
    category: 'Ausführliche Beschreibungen',
    required: true,
  },
  {
    name: 'Ziele, Soll-Beschreibung, Bedarfs-Beschreibung',
    type: 'Text',
    category: 'Ausführliche Beschreibungen',
    required: true,
  },
  {
    name: 'Rahmenbedingungen',
    type: 'Text',
    category: 'Ausführliche Beschreibungen',
  },
  {
    name: 'Investitionskostenziel',
    description: 'in €',
    type: 'Number',
    category: 'Ausführliche Beschreibungen',
  },
  {
    name: 'Zusatzerlöse',
    type: 'Text',
    category: 'Ausführliche Beschreibungen',
  },
  {
    name: 'Zusatzkosten',
    type: 'Text',
    category: 'Ausführliche Beschreibungen',
  },
  {
    name: 'Wirtschaftliche Indikation',
    type: 'Text',
    category: 'Ausführliche Beschreibungen',
  },
  {
    name: 'Voraussichtliche Nutzungsdauer',
    description: 'in Jahren',
    type: 'Number',
    category: 'Ausführliche Beschreibungen',
  },
  {
    name: 'Kostenstelle für die Bearbeitung im Zuge der Bedarfsbewertung / Anforderungskonzeption',
    type: 'Select',
    category: 'Ausführliche Beschreibungen',
    options: ['Planprojekt', 'CO-Innenauftrag', 'Kostenstelle eines Bereiches'],
  },
  {
    name: FRAPORT_CUSTOM_FIELD_REF_NAME,
    type: 'MultiSelect',
    category: 'Ausführliche Beschreibungen',
  },
  {
    name: 'Erläuterung der Schnittstellen',
    type: 'Text',
    category: 'Ausführliche Beschreibungen',
  },
  {
    name: 'Weitere Schnittstellen',
    type: 'Text',
    category: 'Ausführliche Beschreibungen',
    required: true,
  },
  {
    name: 'Risiken',
    type: 'Text',
    category: 'Ausführliche Beschreibungen',
    required: true,
  },
];

export const FRAPORT_REQUIRED_CUSTOM_FIELDS = FRAPORT_CUSTOM_FIELDS.filter((item) => item.required);

interface RequirementDetailsProps {
  requirement: RequirementReadModel;
  formRef: RefObject<FormRefHandle<RequirementUpdateFormValidationValues>>;
  setUnsavedData: (unsavedData: boolean) => void;
  titleError: boolean;
  setTitleError: (codeError: boolean) => void;
  setClusterItemChanged: (changed: boolean) => void;
}

export const RequirementDetails = (props: RequirementDetailsProps) => {
  const {
    requirement,
    formRef,
    setUnsavedData,
    setTitleError,
    setClusterItemChanged
  } = props;
  const {t} = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();
  const ui = useUi();
  const navigate = useNavigate();

  const [updateRequirement, {isLoading: isUpdating}] = useApiPostUpdateClusterItemRequirementMutation();
  const {data: clusterItemsResponse} = useApiGetClusterItemsQuery(
    {
      portfolioId: loadedPortfolioId ?? '',
    },
    {
      skip: !loadedPortfolioId,
    },
  );

  const {data: clusterResponse, isFetching} = useApiGetClusterItemsTreeQuery(
    {
      portfolioId: loadedPortfolioId ?? '',
    },
    {
      skip: !loadedPortfolioId,
    },
  );

  const [currentStep, setCurrentStep] = useState(0);
  // const totalPages = 2;
  // const currentPage = 1;

  const [updatedFields, setUpdatedFields] = useState<UpdateRequirementCustomFieldPayload[]>(FRAPORT_CUSTOM_FIELDS);
  const [deputyName, setDeputyName] = useState<string | null>(null);
  const [allowChangeMode, setAllowChangeMode] = useState(true);
  const [changedFields, setChangedFields] = useState<string[]>([]);
  const [clusterItemName, setClusterItemName] = useState('');

  const isReporter = useMemo(() => {
    return checkIsReporter(requirement.reportedById, requirement.reportedByDeputyId, ui.appUser.userId);
  }, [ui.appUser.userId, requirement.reportedByDeputyId, requirement.reportedById]);

  const isEditable = useMemo(() => {
    return (requirement.state === 'Created' || requirement.state === 'Rejected') && isReporter;
  }, [requirement.state, isReporter]);

  const allExistingRequirements = useMemo(() => {
    return clusterItemsResponse?.clusterItems
      ? clusterItemsResponse.clusterItems.reduce((requirements, clusterItem) => {
        return [...requirements, ...clusterItem.requirements];
      }, [] as RequirementShortReadModel[])
      : [];
  }, [clusterItemsResponse?.clusterItems]);

  const getRequirementLabels = useCallback(
    (fieldText?: string | null) => {
      const reqIds = fieldText ? fieldText.split(', ') : [];
      if (reqIds.length) {
        const reqNames = allExistingRequirements.filter((req) => reqIds.includes(req.id)).map((req) => req.name);
        return reqNames.join(', ');
      }
      return fieldText;
    },
    [allExistingRequirements],
  );

  const getOriginalValue = useCallback(
    (name: string) => {
      const foundField = requirement.customFields.find((field) => field.name === name);
      if (foundField) {
        switch (foundField.type) {
          case 'MultiSelect':
          case 'Select':
          case 'Text':
            return foundField.text;
          case 'Number':
            return foundField.number;
          case 'Date':
            return foundField.date;
          default:
            break;
        }
      }
      return null;
    },
    [requirement.customFields],
  );

  const requirementOptions = useMemo(() => {
    return allExistingRequirements
      .filter((req) => req.id !== requirement.id)
      .map((req) => {
        return {label: `${req.name} - ${req.code}`, value: req.id};
      });
  }, [allExistingRequirements, requirement.id]);

  const getSelectOptions = useCallback((options?: string[] | null) => {
    return (
      options?.map((option) => {
        return {label: option, value: option};
      }) ?? []
    );
  }, []);

  useEffect(() => {
    if (requirement.state === 'Released') {
      setCurrentStep(1);
    }
  }, [requirement.state]);

  const reporterDeputyOptions = useMemo(() => {
    const options: BaseSelectOption[] = [];
    if (requirement?.clusterItem.id) {
      // find all the teams with users that are allowed to see the cluster item and are in the same team as the reporter
      const users = getClusterItemUsers(requirement.clusterItem.id, requirement.reportedById);
      users.forEach((team) => {
        const userOptions: BaseSelectOption[] = [];
        team.users.forEach((teamUser) => {
          // reported him / herself cannot be deputy
          if (teamUser.id !== requirement.reportedById) {
            userOptions.push({
              label: teamUser.name,
              value: teamUser.id,
            });
          }
        });
        options.push({
          label: team.name,
          value: team.name,
          disabled: true,
          options: userOptions,
        });
      });
    }
    return options;
  }, [requirement?.clusterItem.id, requirement?.reportedById]);

  useEffect(() => {
    setDeputyName(requirement.reportedByDeputyName ?? '');
  }, [requirement.reportedByDeputyName]);

  useEffect(() => {
    let additionalFields: UpdateRequirementCustomFieldPayload[] = [...FRAPORT_CUSTOM_FIELDS];
    if (requirement?.customFields) {
      additionalFields = additionalFields.map((field) => {
        const originalValue = getOriginalValue(field.name);
        if (originalValue !== null) {
          let value = {};
          switch (field.type) {
            case 'MultiSelect':
            case 'Select':
            case 'Text':
              value = {text: originalValue};
              break;
            case 'Number':
              value = {number: originalValue};
              break;
            case 'Date':
              value = {date: originalValue};
              break;
            default:
              break;
          }
          return {...field, ...value};
        } else if (field.type === 'MultiSelect' && field.name === FRAPORT_CUSTOM_FIELD_REF_NAME) {
          const reqOptions = [...allExistingRequirements].map((req) => req.id);
          return {...field, options: reqOptions};
        }
        return field;
      });
    }
    setUpdatedFields(additionalFields);
  }, [requirement, getOriginalValue, allExistingRequirements]);

  const breadcrumbItems: BreadcrumbItemProps[] = [
    {
      title: t('portfolio.projecting.steps.notification'),
      active: currentStep === 0,
      icon: <CheckDollarIcon/>,
      onClick: () => {
        setCurrentStep(0);
      },
    },
    {
      title: t('portfolio.projecting.steps.evaluation'),
      active: currentStep === 1,
      icon: <SliderControlFilledIcon/>,
      onClick: requirement.state === 'Released' ? () => setCurrentStep(1) : undefined,
    },
    {
      title: t('portfolio.projecting.steps.concept'),
      active: currentStep === 2,
      icon: <HelpingHandFilledIcon/>,
    },
    {
      title: t('portfolio.projecting.steps.guidingDocument'),
      active: currentStep === 3,
      icon: <PaidBillFilledIcon/>,
    },
  ];

  const getCategoryLabel = (category: RequirementCategory) => {
    switch (category) {
      case 'GesetzlicheAnforderung':
        return 'Gesetzliche Anforderung';
      case 'Mängelbeseitigung':
        return 'Mängelbeseitigung aus Prüfungen, die durch Gesetzte oder Verordnungen vorgeschrieben sind';
      case 'Bestandserhalt':
        return 'Bestandserhalt / Bestandsverbesserung';
      case 'Arbeitsschutz':
        return 'Arbeitsschutz und Unfallverhütung';
      case 'KapazitiverAusbau':
        return 'Kapazitiver Ausbau';
      case 'Grenzkapazität':
        return 'Grenzkapazität';
      case 'Kapazitätserweiterung':
        return 'Sonstige Kapazitätserweiterung';
      case 'UmsatzwirkungExtern':
        return 'Umsatzwirkung extern';
      case 'UmsatzwirkungEntgelte':
        return 'Umsatzwirkung Entgelte';
      default:
        return category;
    }
  };

  const categoryOptions: BaseSelectOption[] = useMemo(() => {
    return [
      {
        label: 'Gesetzliche Anforderung',
        value: 'GesetzlicheAnforderung',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">Gesetzliche Anforderung</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahme auf Grund von Anforderungen aus Gesetzen und Verordnungen sowie behördlichen Auflagen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: 'Mängelbeseitigung aus Prüfungen, die durch Gesetze oder Verordnungen vorgeschrieben sind',
        value: 'Mängelbeseitigung',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">
                Mängelbeseitigung aus Prüfungen, die durch Gesetze oder Verordnungen vorgeschrieben sind
              </div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahme zur Beseitigung von Mängeln, die bei Prüfungen festgestellt wurden, die durch Gesetze oder
                Verordnungen vorgeschrieben sind (z. B. Prüfungen gem. TPrüfV oder BetrSichV, Gefahrenverhütungsschau
                gem. HBKG, Wiederkehrende Sicherheitsüberprüfungen von Sonderbauten im Bestand gem. HBO etc.).
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: 'Bestandserhalt / Bestandsverbesserung',
        value: 'Bestandserhalt',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">Bestandserhalt /
                Bestandsverbesserung
              </div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahme zum Erhalt oder zur Verbesserung des Bestandes aus Eigeninteresse des Eigentümers bzw.
                Betreibers ohne gesetzliche oder behördliche Anforderung.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: 'Arbeitsschutz und Unfallverhütung',
        value: 'Arbeitsschutz',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">Arbeitsschutz und
                Unfallverhütung
              </div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahme zur Sicherstellung und Verbesserung des Schutzes der Beschäftigten vor berufsbedingten Gefahren
                und schädigenden Belastungen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: 'Kapazitiver Ausbau',
        value: 'KapazitiverAusbau',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">Kapazitiver Ausbau</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen, die aus dem kapazitativen Ausbau resultieren (ggf. mit Planänderung) oder zur Erreichung der
                beschlossenen Kapazitätseckwerte dienen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: 'Grenzkapazität',
        value: 'Grenzkapazität',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">Grenzkapazität</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen mit Auswirkungen auf die Grenzkapazität, die aus einer betrieblichen Notwendigkeit resultieren
                und zum Abwenden betrieblicher Einschränkungen dienen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: 'Sonstige Kapazitätserweiterung',
        value: 'Kapazitätserweiterung',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">Sonstige
                Kapazitätserweiterung
              </div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen ohne Auswirkungen auf die Grenzkapazität, die aus einer betrieblichen Notwendigkeit
                resultieren und zum Abwenden betrieblicher Einschränkungen dienen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: 'Umsatzwirkung extern',
        value: 'UmsatzwirkungExtern',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">Umsatzwirkung extern</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen, die auf Basis von Anforderungen externer Kunden oder interner Produktziele oder strategischer
                Überlegungen durchgeführt werden und zur Erzielung externer Umsatzeinnahmen führen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: 'Umsatzwirkung Entgelte',
        value: 'UmsatzwirkungEntgelte',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">Umsatzwirkung Entgelte</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Umsetzungen, die aufgrund von Anforderungen externer Kunden oder interner Produkte, oder aus
                strategischen Gründen erfolgen und durch Einnahmen gedeckt werden.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      /*
      {
        label: t('portfolio.projecting.requirementCategory.accidentPrevention'),
        value: 'AccidentPrevention',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.accidentPrevention')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahme zur Beseitigung intern identifizierter Gefahrenpotentiale (i.W. Arbeitsschutz, SMS, VSA).
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: t('portfolio.projecting.requirementCategory.capacity'),
        value: 'Capacity',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.capacity')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen, die aus einer betrieblichen Notwendigkeit resultieren und zum Abwenden betrieblicher Einschränkungen dienen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: t('portfolio.projecting.requirementCategory.internal'),
        value: 'Internal',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.internal')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen, die seitens Fraport-interner Bereiche / Segmente angefordert werden und keiner der verbleibenden Kategorien zugeordnet werden können.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: t('portfolio.projecting.requirementCategory.capacityExpansion'),
        value: 'CapacityExpansion',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.capacityExpansion')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen, die aus dem kapazitativen Ausbau resultieren (ggf. mit Planänderung) oder zur Erreichung der beschlossenen Kapazitätseckwerte dienen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: t('portfolio.projecting.requirementCategory.defectCorrectionInternal'),
        value: 'DefectCorrectionInternal',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.defectCorrectionInternal')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahme mit geringem Planungsaufwand, die der Beseitigung von Mängeln dient und nicht behördlich angeordnet wurde bzw. rechtlich gefordert ist (gilt nicht für EASA-relevante Infrastruktur).
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: t('portfolio.projecting.requirementCategory.regulatory'),
        value: 'Regulatory',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.regulatory')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen auf Grund von behördlichen Auflagen & aktueller Rechtsnormen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: t('portfolio.projecting.requirementCategory.renovation'),
        value: 'Renovation',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.renovation')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Sanierungen der bestehenden Infrastruktur in Form von Ersatzbauten mit Abriss der Altbauten oder Sanierung zum Erhalt der bestehenden Infrastruktur.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: t('portfolio.projecting.requirementCategory.capacityExpansionOther'),
        value: 'CapacityExpansionOther',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.capacityExpansionOther')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen, die aus einer betrieblichen Notwendigkeit resultieren und zum Abwenden betrieblicher Einschränkungen dienen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: t('portfolio.projecting.requirementCategory.salesImpactFees'),
        value: 'SalesImpactFees',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.salesImpactFees')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen, die auf Grund externer Kunden- oder interner Produktanforderungen bzw. strateg. Überlegungen umgesetzt werden und durch Entgelte refinanziert werden.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      },
      {
        label: t('portfolio.projecting.requirementCategory.salesImpactExternal'),
        value: 'SalesImpactExternal',
        info: (
          <BaseSelectOptionInfoTooltip>
            <div className="text-white">
              <div className="text-xs font-bold px-3.5 py-2 border-b-2 border-b-sky-700">{t('portfolio.projecting.requirementCategory.salesImpactExternal')}</div>
              <p className="text-[11px] leading-4 px-3.5 py-2">
                Maßnahmen, die auf Grund externer Kunden- oder interner Produktanforderungen bzw. strateg. Überlegungen umgesetzt wer¬den und zur Generierung externer Umsatzerlöse beitragen.
              </p>
            </div>
          </BaseSelectOptionInfoTooltip>
        )
      }, */
    ];
  }, []);

  const defaultFormValues = {
    name: requirement.name,
    code: requirement.code,
    clusterItemId: requirement.clusterItem.id,
    category: requirement.category ?? null,
    description: requirement.description ?? '',
    reportedByDeputyId: requirement.reportedByDeputyId ?? null,
  };

  const handleSubmit = async (data: RequirementUpdateFormValidationValues) => {
    if (loadedPortfolioId) {
      try {
        await safeMutation(
          updateRequirement,
          {
            requirementId: requirement.id,
            clusterItemId: requirement.clusterItem.id,
            portfolioId: loadedPortfolioId,
            body: {
              name: data.name,
              clusterItemId: data.clusterItemId,
              category: data.category,
              description: data.description,
              reportedByDeputyId: data.reportedByDeputyId,
              customFields: updatedFields,
            },
          },
          isUpdating,
        );
        if (requirement.clusterItem.id !== data.clusterItemId) {
          navigate(
            ROUTES_CONFIG.PORTFOLIO_PROJECTING_REQUIREMENT_VIEW.path
              .replace(':id', loadedPortfolioId)
              .replace(':clusterItemId', data.clusterItemId)
              .replace(':requirementId', requirement.id),
          );
        }
        setChangedFields([]);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const customFieldSections = useMemo(() => {
    return [...new Set(FRAPORT_CUSTOM_FIELDS.map((item) => item.category))];
  }, []);

  const getFieldsForSection = useCallback(
    (section: string) => {
      return updatedFields.filter((item) => item.category === section && !FRAPORT_COLLAPSIBLE_ITEMS.includes(item.name));
    },
    [updatedFields],
  );

  const getCollapsibleFieldsForSection = useCallback(
    (section: string) => {
      return updatedFields.filter((item) => item.category === section && FRAPORT_COLLAPSIBLE_ITEMS.includes(item.name));
    }, [updatedFields]);

  const updateCustomFields = useCallback(
    (
      name: string,
      type: RequirementCustomFieldType,
      text?: string | null,
      date?: string | null,
      number?: number | null,
    ) => {
      const toUpdate = [...updatedFields];
      const foundIndex = toUpdate.findIndex((field) => field.name === name);
      if (foundIndex >= 0) {
        switch (type) {
          case 'MultiSelect':
          case 'Select':
          case 'Text':
            toUpdate[foundIndex].text = text;
            break;
          case 'Date':
            toUpdate[foundIndex].date = date;
            break;
          case 'Number':
            toUpdate[foundIndex].number = number;
            break;
        }
        setUpdatedFields(toUpdate);
      }
    },
    [updatedFields],
  );

  const updateChangedFields = useCallback(
    (name: string, isOriginalValue: boolean) => {
      const newChangedFields = [...changedFields];
      const foundIndex = newChangedFields.indexOf(name);
      if (foundIndex >= 0) {
        if (isOriginalValue) {
          newChangedFields.splice(foundIndex, 1);
        }
      } else {
        if (!isOriginalValue) {
          newChangedFields.push(name);
        }
      }
      setChangedFields(newChangedFields);
    },
    [changedFields],
  );

  useEffect(() => {
    setUnsavedData(changedFields.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedFields]);

  const status = useMemo(() => {
    return getRequirementStatusBadge(requirement.state);
  }, [requirement.state]);

  const getRequiredFieldText = useCallback(
    (requiredField?: string | null) => {
      return !requiredField && isEditable ? (
        <div className="flex gap-2 text-red-700 italic text-sm items-center font-normal">
          <ErrorIcon className="w-6"/>
          {t('portfolio.projecting.requirementEdit.isRequired')}
        </div>
      ) : (
        requiredField ?? '-'
      );
    },
    [t, isEditable],
  );

  const getCustomFieldValueTitle = useCallback(
    (item: UpdateRequirementCustomFieldPayload, originalValue?: string | number | null) => {
      let value: string | null | undefined = null;

      switch (item.type) {
        case 'MultiSelect':
          value =
            typeof item.text !== 'undefined' && item.text !== ''
              ? item.name === FRAPORT_CUSTOM_FIELD_REF_NAME
                ? getRequirementLabels(item.text)
                : item.text
              : originalValue
                ? item.name === FRAPORT_CUSTOM_FIELD_REF_NAME
                  ? getRequirementLabels(originalValue.toString())
                  : originalValue.toString()
                : null;
          break;
        case 'Select':
          value = typeof item.text !== 'undefined' ? item.text : originalValue ? originalValue.toString() : null;
          break;
        case 'Date':
          value =
            typeof item.date !== 'undefined'
              ? item.date === null ? '-' : formatDate(new Date(item.date))
              : originalValue
                ? formatDate(new Date(originalValue.toString()))
                : null;
          break;
        case 'Number':
          value =
            typeof item.number !== 'undefined'
              ? formatNumber(item.number)
              : originalValue
                ? originalValue.toString()
                : null;
          break;
        case 'Text':
          value = typeof item.text !== 'undefined' ? item.text : originalValue?.toString() ?? null;
          break;
        default:
          break;
      }

      return item.required ? getRequiredFieldText(value) : value ?? '-';
    },
    [getRequirementLabels, getRequiredFieldText],
  );

  const clusterOptions: ComboSelectOption[] = useMemo(() => {
    let options: ComboSelectOption[] = [];
    if (clusterResponse?.clusterItemsTree) {
      if (clusterResponse.clusterItemsTree.children) {
        options = getClusterListItems(clusterResponse.clusterItemsTree.children, ui.appUser.userId);
      }
    }
    return options;
  }, [clusterResponse?.clusterItemsTree, ui.appUser.userId]);

  useEffect(() => {
    setClusterItemName(requirement.clusterItem.name);
  }, [requirement.clusterItem?.name]);

  const hasMultipleTargetDates = useMemo(() => {
    let multiple = false;
    FRAPORT_COLLAPSIBLE_ITEMS.forEach((item) => {
      const originalValue = getOriginalValue(item);
      if (typeof originalValue !== 'undefined') {
        multiple = true;
      }
    })
    return multiple;
  }, [getOriginalValue])

  return (
    <>
      {isUpdating && (
        <LoadingIndicator text={t('portfolio.projecting.updatingRequirementLoadingIndicator')} mode="overlay"/>
      )}
      <Breadcrumb items={breadcrumbItems}/>
      {/*
      {totalPages > 1 && (
        <SliderPagination
          currentPage={currentPage}
          totalPages={totalPages}
          title={requirement.name}
          subtitle={t('portfolio.projecting.requirement')}
        />
      )}
      */}
      {currentStep === 0 ? (
        <Form<RequirementUpdateFormValidationValues>
          onSubmit={handleSubmit}
          validationSchema={RequirementUpdateFormValidationSchema}
          defaultValues={defaultFormValues}
          className="h-full"
          ref={formRef}
        >
          <FormWatch
            onChange={({name, category, reportedByDeputyId, description, clusterItemId}) => {
              if (
                name !== defaultFormValues.name ||
                category !== defaultFormValues.category ||
                reportedByDeputyId !== defaultFormValues.reportedByDeputyId ||
                description !== defaultFormValues.description ||
                clusterItemId !== defaultFormValues.clusterItemId
              ) {
                setUnsavedData(true);
              } else {
                setUnsavedData(changedFields.length > 0);
              }
            }}
            fieldNames={['name', 'category', 'reportedByDeputyId', 'description', 'clusterItemId']}
          >
            {({name, category, reportedByDeputyId, description, clusterItemId}) => (
              <div className="flex flex-col gap-9">
                <DecoratedCard>
                  <DecoratedCardHeader>
                    {t('portfolio.projecting.requirementEdit.requirementInformation')}
                  </DecoratedCardHeader>
                  <DecoratedCardContent className="w-full py-6 px-8 flex flex-col gap-6">
                    <DescriptionSection title="Titel">
                      <DocumentViewerFileDataInlineEdit
                        marginX=""
                        marginY="my-0"
                        closeOnBlur={false}
                        toggleContentClassName=""
                        toggleContent={
                          isEditable ? (
                            <FormField name="name">
                              {(control) => (
                                <TextInput
                                  label=""
                                  {...control}
                                  onChange={(val) => {
                                    setTitleError(!val);
                                    control.onChange(val);
                                  }}
                                />
                              )}
                            </FormField>
                          ) : undefined
                        }
                      >
                        <DescriptionSectionBlock
                          largeTitle={getRequiredFieldText(name)}
                          changed={name && name !== defaultFormValues.name}
                          disabled={!isEditable}
                        />
                      </DocumentViewerFileDataInlineEdit>
                    </DescriptionSection>
                    <DescriptionSection title={t('portfolio.projecting.requirementEdit.number')}>
                      <DescriptionSectionBlock largeTitle={requirement.code} disabled={!isEditable}/>
                    </DescriptionSection>
                    <DescriptionSection title={t('portfolio.projecting.createWizard.cluster')} border>
                      <DocumentViewerFileDataInlineEdit
                        marginX=""
                        marginY="my-0"
                        closeOnBlur={false}
                        allowChangeMode={allowChangeMode}
                        toggleContentClassName=""
                        toggleContent={
                          isEditable ? (
                            <FormField name="clusterItemId">
                              {(control) => (
                                <ComboSelect
                                  nullable={false}
                                  label=""
                                  options={clusterOptions}
                                  {...control}
                                  onChange={(val, label) => {
                                    setClusterItemName(label ?? '');
                                    setClusterItemChanged(val !== defaultFormValues.clusterItemId);
                                    control.onChange(val);
                                  }}
                                  helperTextClassName="bg-transparent"
                                  disabled={isFetching}
                                />
                              )}
                            </FormField>
                          ) : undefined
                        }
                      >
                        <DescriptionSectionBlock
                          largeTitle={clusterItemName}
                          changed={clusterItemId !== defaultFormValues.clusterItemId}
                          icon={isEditable ? <ChevronDownIcon className="w-5"/> : undefined}
                          disabled={!isEditable}
                        />
                      </DocumentViewerFileDataInlineEdit>
                    </DescriptionSection>
                    <DescriptionSection title={t('portfolio.projecting.requirementEdit.nameReporter')}>
                      <DescriptionSectionBlock largeTitle={requirement.createdByName} disabled/>
                    </DescriptionSection>
                    <DescriptionSection title={t('portfolio.projecting.requirementEdit.reporterTeam')}>
                      <DescriptionSectionBlock
                        largeTitle={
                          getOrgaUnitNamesForUser(requirement.reportedById, requirement.clusterItem.id).join(', ') ??
                          '-'
                        }
                        disabled
                      />
                    </DescriptionSection>
                    <DescriptionSection
                      title={t('portfolio.projecting.requirementEdit.nameReporterDeputy')}
                      // subtitle={t('portfolio.projecting.requirementEdit.deputy')}
                    >
                      <DocumentViewerFileDataInlineEdit
                        marginX=""
                        marginY="my-0"
                        closeOnBlur={false}
                        allowChangeMode={allowChangeMode}
                        toggleContentClassName=""
                        toggleContent={
                          isEditable && requirement.reportedByDeputyId !== ui.appUser.userId ? (
                            <FormField name="reportedByDeputyId">
                              {(control) => (
                                <BaseSelect
                                  label=""
                                  options={reporterDeputyOptions}
                                  {...control}
                                  onChange={(val) => {
                                    const reporterName = getReporterNameById(val);
                                    setDeputyName(reporterName);
                                    control.onChange(val);
                                  }}
                                  handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                />
                              )}
                            </FormField>
                          ) : undefined
                        }
                      >
                        <DescriptionSectionBlock
                          largeTitle={getRequiredFieldText(deputyName)}
                          changed={reportedByDeputyId !== defaultFormValues.reportedByDeputyId}
                          icon={isEditable ? <ChevronDownIcon className="w-5"/> : undefined}
                          disabled={requirement.reportedByDeputyId === ui.appUser.userId || !isEditable}
                        />
                      </DocumentViewerFileDataInlineEdit>
                    </DescriptionSection>
                    <DescriptionSection
                      title={t('portfolio.projecting.requirementEdit.reporterTeamDeputy')}
                      // subtitle={t('portfolio.projecting.requirementEdit.deputy')}
                      border
                    >
                      <DescriptionSectionBlock
                        largeTitle={
                          reportedByDeputyId || requirement.reportedByDeputyId
                            ? getOrgaUnitNamesForUser(
                            reportedByDeputyId ?? requirement.reportedByDeputyId,
                            requirement.clusterItem.id,
                            requirement.reportedById,
                          ).join(', ') ?? '-'
                            : '-'
                        }
                        disabled
                      />
                    </DescriptionSection>
                    <DescriptionSection title={t('portfolio.projecting.requirementEdit.category')}>
                      <DocumentViewerFileDataInlineEdit
                        marginX=""
                        marginY="my-0"
                        closeOnBlur={false}
                        allowChangeMode={allowChangeMode}
                        toggleContentClassName=""
                        toggleContent={
                          isEditable ? (
                            <FormField name="category">
                              {(control) => (
                                <BaseSelect
                                  label=""
                                  options={categoryOptions}
                                  {...control}
                                  handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                                />
                              )}
                            </FormField>
                          ) : undefined
                        }
                      >
                        <DescriptionSectionBlock
                          largeTitle={getRequiredFieldText(getCategoryLabel(category ?? requirement.category))}
                          changed={category && category !== requirement.category}
                          icon={isEditable ? <ChevronDownIcon className="w-5"/> : undefined}
                          disabled={!isEditable}
                        />
                      </DocumentViewerFileDataInlineEdit>
                    </DescriptionSection>
                  </DecoratedCardContent>
                </DecoratedCard>
                {requirement.comment && (
                  <DecoratedCard>
                    <DecoratedCardHeader>{t('portfolio.projecting.requirementEdit.status')}</DecoratedCardHeader>
                    <DecoratedCardContent className="w-full py-6 px-8 flex flex-col gap-6">
                      <DescriptionSection title={t('portfolio.projecting.requirementEdit.status')}>
                        <DescriptionSectionBlock
                          className="pt-1"
                          text={status ? <Badge {...status} /> : ''}
                          disabled={!isEditable}
                        />
                      </DescriptionSection>
                      <DescriptionSection
                        title={
                          requirement.state === 'Rejected'
                            ? t('portfolio.projecting.requirementEdit.reason')
                            : t('portfolio.projecting.submitNotificationWizard.comment')
                        }
                      >
                        <DescriptionSectionBlock text={requirement.comment} disabled={!isEditable}/>
                      </DescriptionSection>
                    </DecoratedCardContent>
                  </DecoratedCard>
                )}
                {customFieldSections.map((section) => (
                  <DecoratedCard key={section}>
                    <DecoratedCardHeader>{section}</DecoratedCardHeader>
                    <DecoratedCardContent className="w-full py-6 px-8 flex flex-col gap-6">
                      {section === 'Management Summary' && (
                        <DescriptionSection title={t('portfolio.projecting.requirementEdit.description')}>
                          <DocumentViewerFileDataInlineEdit
                            marginX=""
                            marginY="my-0"
                            closeOnBlur={false}
                            toggleContentClassName=""
                            toggleContent={
                              isEditable ? (
                                <FormField name="description">
                                  {(control) => <TextInput label="" inputType="textarea" {...control} />}
                                </FormField>
                              ) : undefined
                            }
                          >
                            <DescriptionSectionBlock
                              text={getRequiredFieldText(description ? description : requirement.description)}
                              changed={description !== requirement.description}
                              disabled={!isEditable}
                            />
                          </DocumentViewerFileDataInlineEdit>
                        </DescriptionSection>
                      )}
                      {getFieldsForSection(section).map((item, i) => {
                        const originalValue = getOriginalValue(item.name);
                        const border = getCollapsibleFieldsForSection(section).length ? true : i < getFieldsForSection(section).length - 1;
                        return (
                          <RequirementCustomField
                            item={item}
                            section={section}
                            border={border}
                            isEditable={isEditable}
                            updateCustomFields={updateCustomFields}
                            updateChangedFields={updateChangedFields}
                            getCustomFieldValueTitle={getCustomFieldValueTitle}
                            allowChangeMode={allowChangeMode}
                            setAllowChangeMode={setAllowChangeMode}
                            requirementOptions={requirementOptions}
                            getSelectOptions={getSelectOptions}
                            originalValue={originalValue}
                          />
                        );
                      })}
                      {getCollapsibleFieldsForSection(section).length > 0 && (
                        <Disclosure defaultOpen={hasMultipleTargetDates}>
                          {({ open }) => (
                            <>
                              <DisclosureButton className="text-right" disabled={hasMultipleTargetDates}>
                                <CheckBox checked={open} onChange={() => {
                                  //
                                }} className="mr-2" disabled={hasMultipleTargetDates} />
                                {t('portfolio.projecting.moreTargetDates')}
                              </DisclosureButton>
                              <DisclosurePanel className="flex flex-col gap-6">
                                {getCollapsibleFieldsForSection(section).map((item, i) => {
                                  const originalValue = getOriginalValue(item.name);
                                  const border = i < getCollapsibleFieldsForSection(section).length - 1;
                                  return (
                                    <RequirementCustomField
                                      item={item}
                                      section={section}
                                      border={border}
                                      isEditable={isEditable}
                                      updateCustomFields={updateCustomFields}
                                      updateChangedFields={updateChangedFields}
                                      getCustomFieldValueTitle={getCustomFieldValueTitle}
                                      allowChangeMode={allowChangeMode}
                                      setAllowChangeMode={setAllowChangeMode}
                                      requirementOptions={requirementOptions}
                                      getSelectOptions={getSelectOptions}
                                      originalValue={originalValue}
                                    />
                                  );
                                })}
                              </DisclosurePanel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </DecoratedCardContent>
                  </DecoratedCard>
                ))}
              </div>
            )}
          </FormWatch>
        </Form>
      ) : (
        <HintBox title="POC Ende"/>
      )}
    </>
  );
};

const RequirementCustomField = ({
  item,
  section,
  border,
  isEditable,
  updateCustomFields,
  updateChangedFields,
  getCustomFieldValueTitle,
  allowChangeMode,
  setAllowChangeMode,
  requirementOptions,
  getSelectOptions,
  originalValue,
  className
}: {
  item: UpdateRequirementCustomFieldPayload,
  section: string,
  border: boolean;
  isEditable: boolean;
  updateCustomFields: (name: string, type: RequirementCustomFieldType, text?: (string | null), date?: (string | null), number?: (number | null)) => void;
  updateChangedFields: (name: string, isOriginalValue: boolean) => void;
  getCustomFieldValueTitle: (item: UpdateRequirementCustomFieldPayload, originalValue?: (string | number | null)) => (string | JSX.Element);
  allowChangeMode: boolean;
  setAllowChangeMode: (allow: boolean) => void;
  requirementOptions: {label: string, value: string}[];
  getSelectOptions: (options?: (string[] | null)) => {label: string, value: string}[];
  originalValue?: string | number | null;
  className?: string;
}) => {
  return (
    <DescriptionSection
      title={item.name}
      subtitle={item.description ?? ''}
      key={`${section}-${item.name}`}
      border={border}
    >
      {item.type === 'Text' && (
        <DocumentViewerFileDataInlineEdit
          marginX=""
          marginY="my-0"
          closeOnBlur={false}
          toggleContentClassName=""
          toggleContent={
            isEditable ? (
              <TextInput
                label=""
                value={item.text ?? ''}
                inputType="textarea"
                onChange={(value) => {
                  updateCustomFields(item.name, 'Text', value);
                  updateChangedFields(item.name, originalValue === value);
                }}
              />
            ) : undefined
          }
        >
          <DescriptionSectionBlock
            text={getCustomFieldValueTitle(item, originalValue)}
            changed={typeof item.text !== 'undefined' && item.text !== originalValue}
            disabled={!isEditable}
          />
        </DocumentViewerFileDataInlineEdit>
      )}
      {item.type === 'Number' && (
        <DocumentViewerFileDataInlineEdit
          marginX=""
          marginY="my-0"
          closeOnBlur={false}
          toggleContentClassName=""
          toggleContent={
            isEditable ? (
              <NumberInput
                label=""
                value={item.number ?? null}
                onChange={(value) => {
                  updateCustomFields(item.name, 'Number', undefined, undefined, value);
                  updateChangedFields(item.name, originalValue === value);
                }}
                decimalScale={2}
                allowNegative={false}
              />
            ) : undefined
          }
        >
          <DescriptionSectionBlock
            text={getCustomFieldValueTitle(item, originalValue)}
            changed={typeof item.number !== 'undefined' && item.number !== originalValue}
            disabled={!isEditable}
          />
        </DocumentViewerFileDataInlineEdit>
      )}
      {item.type === 'Date' && (
        <DocumentViewerFileDataInlineEdit
          marginX=""
          marginY="my-0"
          closeOnBlur={false}
          allowChangeMode={allowChangeMode}
          toggleContentClassName=""
          toggleContent={
            isEditable ? (
              <DatePicker
                label=""
                value={item.date ?? null}
                onChange={(value) => {
                  console.log(value, 'value')
                  updateCustomFields(
                    item.name,
                    'Date',
                    undefined,
                    value ? formatDateOnly(value) : null,
                    undefined,
                  );
                  updateChangedFields(item.name, originalValue === value);
                }}
                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
              />
            ) : undefined
          }
        >
          <DescriptionSectionBlock
            largeTitle={getCustomFieldValueTitle(item, originalValue)}
            changed={typeof item.date !== 'undefined' && item.date !== originalValue}
            icon={isEditable ? <CalendarDaysIcon className="w-5 h-5"/> : undefined}
            disabled={!isEditable}
          />
        </DocumentViewerFileDataInlineEdit>
      )}
      {item.type === 'Select' && (
        <DocumentViewerFileDataInlineEdit
          marginX=""
          marginY="my-0"
          closeOnBlur={false}
          allowChangeMode={allowChangeMode}
          toggleContentClassName=""
          toggleContent={
            isEditable ? (
              <BaseSelect
                label=""
                nullable
                options={getSelectOptions(item.options)}
                value={item.text ?? ''}
                onChange={(value) => {
                  updateCustomFields(item.name, 'Select', value);
                  updateChangedFields(item.name, originalValue === value);
                }}
                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                className={
                  typeof item.text !== 'undefined' && item.text !== originalValue
                    ? 'text-secondary'
                    : 'text-gray-800'
                }
              />
            ) : undefined
          }
        >
          <DescriptionSectionBlock
            largeTitle={getCustomFieldValueTitle(item, originalValue)}
            changed={typeof item.text !== 'undefined' && item.text !== originalValue}
            icon={isEditable ? <ChevronDownIcon className="w-5"/> : undefined}
            disabled={!isEditable}
          />
        </DocumentViewerFileDataInlineEdit>
      )}
      {item.type === 'MultiSelect' && (
        <DocumentViewerFileDataInlineEdit
          marginX=""
          marginY="my-0"
          closeOnBlur={false}
          allowChangeMode={allowChangeMode}
          toggleContentClassName=""
          toggleContent={
            isEditable ? (
              <MultiSelect
                label=""
                nullable
                options={
                  item.name === FRAPORT_CUSTOM_FIELD_REF_NAME
                    ? requirementOptions
                    : getSelectOptions(item.options)
                }
                value={item.text ? item.text.split(', ') : []}
                onChange={(value) => {
                  updateCustomFields(item.name, 'Select', value.join(', '));
                  updateChangedFields(item.name, originalValue === value.join(', '));
                }}
                // handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                className={
                  typeof item.text !== 'undefined' && item.text !== originalValue
                    ? 'text-secondary'
                    : 'text-gray-800'
                }
              />
            ) : undefined
          }
        >
          <DescriptionSectionBlock
            largeTitle={getCustomFieldValueTitle(item, originalValue)}
            changed={typeof item.text !== 'undefined' && item.text !== originalValue}
            icon={isEditable ? <ChevronDownIcon className="w-5"/> : undefined}
            disabled={!isEditable}
          />
        </DocumentViewerFileDataInlineEdit>
      )}
    </DescriptionSection>
  )
}
