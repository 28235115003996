import { ContractDto } from '@client/shared/api';
import { formatPercentage } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import { CalculateElementRowInformationColumn } from './CalculateElementRowInformationColumn';
import { CalculateInformationColumnContent } from './CalculateGroupElementInformationColumn';
import { CalculateColumns } from './CalculateColumns';
import React from 'react';
import { TimeLineView } from './Timeline';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn } from './CalculateSelectableColumn';
import { DocumentCustomIcon } from '@client/shared/toolkit';

interface CostContractElementProps {
  contract: ContractDto;
  level: number;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  index: number;
  budget: number;
  restBudget: boolean;
  codeWidth: number
  top?: number
}

export const CostContractElement = ({
  contract,
  level,
  optionalColumn,
  obligoColumn,
  index,
  budget,
  restBudget,
  codeWidth,
  top = 0
}: CostContractElementProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="w-full flex group absolute left-0 bg-transparent"
      data-contract={contract.code}
      data-cost-element={contract.costElementId}
      style={{ top: top }}
    >
      <CalculateColumns
        view={TimeLineView.FINANCE}
        informationColumnContent={
          <CalculateElementRowInformationColumn
            contentType="row"
            codeWidth={codeWidth}
            level={level}
            view={TimeLineView.FINANCE}
            leftColumn={
              <CalculateInformationColumnContent
                showCode={!!(contract?.code)}
                code={contract.code}
                level={level}
                contentType="row"
                showExpanded
                marker={
                  <div className="relative">
                    {index === 0 && !restBudget ? (
                      <>
                        <div className="ml-[4px] w-[9px] h-[10px] absolute -mt-[22px] border-slate-400 z-1 rounded-bl-2xl border-l border-b" />
                        <div className="ml-[12px] w-[9px] h-[10px] absolute -mt-[13px] border-slate-400 z-1 rounded-tr-2xl border-r border-t" />
                      </>
                    ) : (
                      <div className="ml-5 w-px h-9 absolute -mt-9 bg-slate-400 z-0" />
                    )}
                    <div className="w-px h-10 absolute ml-1 -mt-9 bg-slate-400 z-0" />
                    <div className="ml-4 w-[0.5625rem] h-[0.5625rem] p-px absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
                  </div>
                }
              >
                <div className="ml-8 truncate flex flex-row items-center">
                  <DocumentCustomIcon className="h-4 w-4 mr-1 opacity-60" />
                  <span className="overflow-x-hidden text-ellipsis flex flex-row font-bold">
                    <div className="font-normal mr-1">{t('projectCalculate.contract')}</div>
                    <div className="mr-1">{contract.name}</div>
                    {(contract.budget !== 0 || contract.budget !== 0) &&
                      budget !== 0 && (
                        <div className="text-xxs opacity-70">
                          {formatPercentage(
                            ((contract.budget || contract.budget) ?? 0) / budget,
                            { maxDigits: 0 }
                          )}
                        </div>
                      )}
                  </span>
                </div>
              </CalculateInformationColumnContent>
            }
            rightColumn={<>right column</>}
          />
        }
        currentColumnContent={
          <CalculateElementRowFinanceColumn
            level={level}
            columnType={Column.CURRENT}
            view={TimeLineView.FINANCE}
          >
            {!!contract.budget && <FormattedCurrency amount={contract.budget} />}
          </CalculateElementRowFinanceColumn>
        }
        obligoColumnContent={
          <CalculateSelectableColumn
            column={obligoColumn}
            level={level}
            columnType={Column.OBLIGO}
            view={TimeLineView.FINANCE}
            values={contract}
            type="contract"
          />
        }
        optionalColumnContent={
          <CalculateSelectableColumn
            column={optionalColumn}
            level={level}
            columnType={Column.OPTIONAL_COLUMN}
            view={TimeLineView.FINANCE}
            values={contract}
            type="contract"
          />
        }
      />
    </div>
  );
};
