import {
  AiEvalValuesReadModel,
  InvoiceReadModel,
  useApiGetJobRouterPdfFileQuery,
  useApiPostCreateInvoiceSourceDocumentMutation
} from '@client/shared/api';
import {
  DocumentViewer,
  FileInput,
  ListTitle,
  UploadToCloudDottedIcon
} from '@client/shared/toolkit';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface InvoiceUploadProps {
  invoice: InvoiceReadModel,
  isJobRouter?: boolean
  setIsLoading?: (isLoading: boolean) => void
}
export const InvoiceUpload = (props: InvoiceUploadProps) => {
  const {
    invoice,
    setIsLoading
  } = props

  const { t } = useTranslation()
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [file] = useState<File | null>(null);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [fileData] = useState<AiEvalValuesReadModel | null | undefined>(null);

  const sourceDocument = useMemo(() => {
    if (invoice.documents) {
      return invoice.documents.find((document) => document.isInvoiceSource)
    }
    return null
  }, [invoice.documents])

  const { data: fetchedJobRouterFile, isFetching } = useApiGetJobRouterPdfFileQuery(
    {
      invoiceId: invoice?.id ?? '',
      calculationModelId: loadedCalculationModelId ?? 'unset',
      projectId: loadedProjectId ?? 'unset',
    },
    {
      skip: true //!isJobRouter || !loadedProjectId || !loadedCalculationModelId || !invoice
    })

  const [uploadDocument, { isLoading }] = useApiPostCreateInvoiceSourceDocumentMutation()

  useEffect(() => {
    if (fetchedJobRouterFile) {
      setPdfFile(fetchedJobRouterFile)
    }
  }, [fetchedJobRouterFile]);

  useEffect(() => {
    if(setIsLoading) {
      setIsLoading(isLoading || isFetching)
    }
  }, [isLoading, isFetching, setIsLoading])

  useEffect(() => {
    if (sourceDocument) {
      setPdfFile(`/api/projects/${loadedProjectId}/calculation-models/${loadedCalculationModelId}/invoices/${invoice.id}/documents/${sourceDocument.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDocument]);

  const handleFileChange = async (files: FileList | null) => {
    if (files?.length && loadedProjectId && loadedCalculationModelId) {
      const formData = new FormData();
      formData.append('file', files[0]);
      try {
        await safeMutation(
          uploadDocument,
          {
            invoiceId: invoice.id,
            projectId: loadedProjectId,
            calculationModelId: loadedCalculationModelId,
            body: formData as unknown as { file: Blob },
          },
          isLoading
        )
      } catch (e) {
        console.error(e)
      }
    }
  };

  return (
    <>
      {!pdfFile && !fileData ? (
        <div className="rounded-md w-full shadow">
          <ListTitle title={t('projectControl.invoice')} />
          <div className="p-8">
            <FileInput
              className="w-full rounded-md shadow bg-white hover:bg-slate-50 focus-within:border-secondary focus-within:border-2 [&:has(:focus-visible)]:border-2"
              icon={<UploadToCloudDottedIcon className="w-16 flex-1" />}
              acceptedFileTypes={['application/pdf']}
              multiple={false}
              selectedFiles={null}
              onChange={handleFileChange}
              uploadDescription={t('projectControl.invoiceUploadMessage')}
            />
          </div>
        </div>
      ) : (
        <DocumentViewer
          menuOptions={{
            fullscreen: false,
          }}
          title={t('projectControl.invoice')}
          file={file}
          pdfFile={pdfFile}
          fileLoaded={fileData !== null}
          loadingMessage={t('projectControl.fetchingInvoiceLoadingIndicator')}
          uploadDescription={t('projectControl.invoiceUploadMessage')}
        />
      )}
    </>
  )
}
