import { lazy, useMemo } from 'react';
import { LazyComponent } from '@client/shared/toolkit';
import {
  useApiGetReportDashboardDataSourceQuery,
  useApiGetReportGenericDataSourceQuery,
  useApiGetReportQuery,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useUi } from '@client/shared/store';

const ReportExportComponent = lazy(() =>
  import('@client/project/lazy/src/components/ReportExport').catch((e) => {
    console.error('Component Failed Loading:', e);
    return { default: () => <div>Error loading the component.</div> };
  }),
);

const LazyExportButtons = (props: {
  reportId: string;
  type?: 'dashboard' | 'generic';
  buttons?: ('png'|'pdf'|'xlxs')[]
}) => {
  const { reportId, type = 'dashboard', buttons } = props;
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const userData = useUi();

  // if reportId is set, the invoice has own report
  const {
    data: reportData,
    isFetching
  } = useApiGetReportQuery({
    reportId: reportId,
  });

  // We need to load the datasource with the correct data, because in report response there are only dummy ids
  const { data: dashboardDataSource, isFetching: isFetchingDashboardDataSource } =
    useApiGetReportDashboardDataSourceQuery(
      {
        dataSourceId: reportData?.dataSourceModel?.id ?? '',
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedVariantId ?? '',
      },
      {
        skip: !loadedProjectId || !loadedVariantId || !reportData?.dataSourceModel?.id || type !== 'dashboard',
      },
    );
  const { data: genericDataSource, isFetching: isFetchingGenericDataSource } = useApiGetReportGenericDataSourceQuery(
    {
      dataSourceId: reportData?.dataSourceModel?.id ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !reportData?.dataSourceModel?.id || type !== 'generic',
    },
  );

  const dataSource = useMemo(() => {
    if (type === 'dashboard') {
      return dashboardDataSource;
    }
    return genericDataSource;
  }, [type, genericDataSource, dashboardDataSource]);

  return (
    <LazyComponent>
      <ReportExportComponent
        isLoading={isFetching || isFetchingDashboardDataSource || isFetchingGenericDataSource}
        reportData={reportData}
        dataSource={dataSource}
        sessionId={userData?.sessionId}
        buttons={buttons}
      />
    </LazyComponent>
  );
};

export default LazyExportButtons;
