import { FontLight } from '../FontLight';
import { LoadingIndicator, WidgetContainer } from '@client/shared/toolkit';
import { DataFeed } from '../ProjectMetadata';
import React, { Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';
import { ElementUserDefinedFieldReadModel } from '@client/shared/api';
import { DashboardWidgetDataFeedConfig, DashboardWidgetVariant } from '../Dashboards';
import { useProjectDashboardData } from '../../hooks';
import { isEmpty } from '@client/shared/utilities';

export interface DashboardWidgetProjectCustomFieldsProps extends PropsWithChildren {
  config?: DashboardWidgetDataFeedConfig[];
  variant?: DashboardWidgetVariant;
  title?: string;
  icon?: ReactNode | string;
}

export const getValueForCustomFieldType = (udf: ElementUserDefinedFieldReadModel) => {
  switch (udf.definition.fieldType) {
    case 'Text':
      return udf.text;
    case 'Number':
      return udf.number;
    case 'List':
      return udf.listSelectedItem?.label;
    default:
      return '';
  }
};
export const DashboardWidgetProjectCustomFields = (props: DashboardWidgetProjectCustomFieldsProps) => {
  const {
    config: customFields,
    variant = 'card',
    children,
    title,
    icon
  } = props;

  const { data: projectData, isFetching } = useProjectDashboardData();

  const foundCustomFields = useMemo(() => {
    const udfData: ReactNode[] = [];
    customFields?.forEach((cf, index) => {
      const foundUdf = projectData?.project?.userDefinedFields?.find((udf) => {
        return udf.definition.name === cf.name;
      });
      let value = foundUdf ? getValueForCustomFieldType(foundUdf) : '-';
      value = isEmpty(value) ? '-' : value;
      let title = customFields && customFields.length > 1 ? <FontLight>{value}</FontLight> : value;
      if (cf.size === 'large') {
        title = value;
      }
      if (foundUdf || (!foundUdf && !cf.hideIfEmpty)) {
        if (cf.variant === 'other') {
          udfData.push(
            <DataFeed
              key={`dashboard-widget-project-custom-field-${index}`}
              footer={<FontLight classNames="text-slate-500">{cf.title ?? cf.name}</FontLight>}
              title={title}
              icon={cf.icon}
              variant="small"
            />
          );
        } else {
          udfData.push(
            <DataFeed
              className={variant === 'child' ? 'border-t' : '[&:not(:first-child)]:border-t'}
              key={`dashboard-widget-project-custom-field-${index}`}
              header={<FontLight classNames="text-slate-500">{cf.title ?? cf.name}</FontLight>}
              title={title}
              icon={cf.icon}
            />,
          );
        }
      }
    });
    return udfData;
  }, [customFields, projectData?.project?.userDefinedFields, variant]);

  const Component = variant === 'card' ? WidgetContainer : Fragment;

  return (
    <Component>
      {isFetching ? (
        <LoadingIndicator className="p-2" />
      ) : (
        <>
          {title && (
            <div className="flex justify-start flex-auto pt-4 px-4 gap-2">
              <FontLight classNames="text-slate-500">{title}</FontLight>
            </div>
          )}
          {foundCustomFields.length > 0 && foundCustomFields.map((data) => data)}
          {children}
          {icon && (
            <div className="z-0 text-slate-200 absolute right-0 bottom-0 -mb-3 w-24">{icon}</div>
          )}
        </>
      )}
    </Component>
  );
};
